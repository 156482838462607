.messages-companion {
    display: flex;
    align-items: center;

        &__info {
            text-align: right;
        }

            &__name {
                display: block;
                margin-bottom: 1px;

                text-decoration: none;
                color: #1e1e24;
                
                transition: .3s;

                    &:hover {
                        opacity: .92;
                    }
            }

            &__status {
                color: #9e9ea3;
                font-weight: 500;
            }

        &__avatar {
            margin-left: 16px;
            width: 40px;
            height: 40px;

            text-decoration: none;
            
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            border-radius: 50%;
        }
}
