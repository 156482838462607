.my-navigation {
    @include nl;

    display: flex;
    justify-content: space-between;
    padding-right: 99px;
    padding-left: 16px;

    border-bottom: 1px solid #f7f7fc;

        &__side {
            display: flex;
        }

            &__item {
                display: block;
                padding-left: 9px;
                padding-right: 11px;
                padding-bottom: 20px;
                padding-top: 23px;
                margin-right: 28px;

                text-decoration: none;
                color: #848488;

                border-bottom: 2px solid transparent;
                
                cursor: pointer;
                transition: .3s;

                    &:hover {
                        color: #64646d;
                    }

                    &._active {

                        color: #1e1e24;

                        border-bottom: 2px solid #e1151e;

                            &:hover {
                                color: #1e1e24;
                            }
                    }

                    &:last-child {
                        margin-right: 0;
                    }
            }
}
