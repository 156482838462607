.sidebar-other {
    margin-bottom: 37px;

    font-weight: 500;

        &__list {
            @include nl;
            
            width: 135px;

                &._more {
                    z-index: 10;
                    position: absolute;
                    bottom: 100%;
                    left: -40px;

                    width: 170px;
                    padding-left: 20px;
                    padding-top: 20px;
                    padding-bottom: 17px;

                    border: 1px solid #f7f7fc;
                    background-color: #fff;
                    border-radius: 3px;
                    opacity: 0;

                    transition: .3s;

                        &._active {
                            opacity: 1;
                        }

                        .sidebar-other__link {

                                &:hover {
                                    padding-left: 0;

                                    color: #4c4c52;
                                }
                        }
                }
        }

            &__item {
                margin-bottom: 11px;

                    &:last-child {
                        margin-bottom: 0;
                    }
            }

                &__link {
                    display: inline-block;

                    text-decoration: none;
                    color: #a2a2a8;

                    transition: .3s;

                        &:hover {
                            padding-left: 5px;

                            color: #74747f;
                        }
                }

        &__more {
            position: absolute;
            right: 54px;
            bottom: 0;
        }

            &__more-item {
                position: relative;

                padding-right: 15px;

                color: #696971;

                cursor: pointer;
                transition: .3s;

                    &:after {
                        content: '';

                        position: absolute;
                        right: 0;
                        top: 5px;

                        display: block;
                        width: 9px;
                        height: 6px;

                        background-image: url(__static__content/more-arrow.svg);
                        background-repeat: no-repeat;
                        background-position: 50% 50%;
                    }

                    &._active {
                        color: #4c4c52;
                    }
            }
}
