.profile-events {
    padding-top: 23px;

        &__item {
            text-decoration: none;
        }

            &__title {
                display: block;
                margin-bottom: 20px;
                padding-left: 1px;

                line-height: 24px;
                font-size: 20px;
                color: #1e1e24;
            }

            &__footer {
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
            }

                &__date {
                    display: block;
                    margin-top: 18px;
                    
                    color: #9e9ea3;
                }

                &__grade {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 3px;
                    width: 30px;
                    height: 30px;

                    font-size: 14px;
                    color: #ffffff;
                    text-transform: uppercase;
                    font-weight: 500;

                    border-radius: 50%;
                    background-color: #90d658;

                        &._color-1 {
                            color: rgba(0,0,0,.63);
                            
                            background-color: #ff5f40;
                        }

                        &._color-2 {
                            color: rgba(0,0,0,.63);
                            
                            background-color: #ffa340;
                        }

                        &._color-3 {
                            color: rgba(0,0,0,.63);

                            background-color: #deef27;
                        }

                        &._color-4 {
                            color: #ffffff;

                            background-color: #86d646;
                        }

                        &._color-5 {
                            color: #ffffff;

                            background-color: #56bf36;
                        }
                }
}
