.dialogs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 18px;
    padding-left: 24px;
    padding-right: 24px;

        &._important {

                .dialogs-header__search {
                    opacity: 0;

                    pointer-events: none;
                }
        }

        &__search {

        }

            &__search-icon {
                width: 40px;
                height: 40px;

                background-color: #f5f5fa;
                border-radius: 50%;
                background-image: url(__static__content/search-icon.png);
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 16px;

                transition: background-color .3s;
                cursor: pointer;

                    &._close {
                        background-color: $main-color;
                        background-size: 14px;
                        background-image: url(__static__content/close-icon.svg);
                    }
            }

        &__title {
            color: #1e1e24;
        }

        &__sound {
            position: relative;

            width: 40px;
            height: 40px;

            text-decoration: none;

            border-radius: 50%;
            background-image: url(__static__content/no-sound-icon.svg);
            background-repeat: no-repeat;
            background-position: 50% 47%;
            background-size: 17px;
            background-color: #f5f5fa;

            transition: background-color .3s;

                &._active {
                    background-image: url(__static__content/no-sound-icon-active.svg);
                    background-color: $main-color;

                        &:before {
                            content: 'Выключить уведомления';
                        }
                }

                &:before {
                    content: 'Включить уведомления';

                    position: absolute;
                    top: -37px;
                    left: 50%;
                    width: 160px;

                    display: block;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    margin-left: -75px;

                    color: #ffffff;
                    font-weight: 500;
                    font-size: 12px;
                    text-align: center;

                    border-radius: 6px;
                    background-color: rgba(30,30,36,.9);
                    opacity: 0;

                    pointer-events: none;

                    transition: .3s;
                }

                &:hover {

                        &:before {
                            z-index: 10;

                            opacity: 1;
                        }
                }
        }
}