.pop-ups {

        &__overlay {
            z-index: -5;
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

                &._active {
                    z-index: 100000;

                    background-color: rgba(0,0,0,.64);
                }
        }
}
