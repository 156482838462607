.create-chat {
    z-index: -10;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    
    padding-top: 146px;

    border-radius: 3px 3px 0 0;
    border: 1px solid #f7f7fc;
    background-color: #ffffff;
    opacity: 0;
    
    transition: .3s;

    overflow: hidden;

    pointer-events: none;

        &._active {
            z-index: 20;

            opacity: 1;

            overflow: inherit;

            pointer-events: auto;
        }

        &__header {
            z-index: 10;
            position: fixed;
            top: 0;

            width: 670px;
            padding-top: 56px;
            padding-right: 159px;
            padding-left: 159px;

            background-color: #ffffff;
        }

            &__title {
                display: block;
                margin-bottom: 27px;

                text-align: center;
                color: #1e1e24;
            }

            &__form {
                position: relative;
            }

                &__search {
                    display: block;
                    width: 100%;
                    padding-top: 15px;
                    padding-bottom: 14px;
                    padding-left: 13px;

                    color: #1e1e24;
                    
                    border: none;
                    border-radius: 3px;
                    background-color: #f7f7fc;
                    border: 1px solid rgba(208,208,217,0);

                    transition: .3s;

                        &:focus {
                            background-color: #fff;

                            border: 1px solid rgba(208,208,217,1);
                        }
                }

                &__form-icon {
                    position: absolute;
                    top: 50%;
                    right: 14px;

                    display: block;
                    width: 20px;
                    height: 20px;
                    margin-top: -10px;

                    background-image: url(__static__content/search-icon.png);
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: cover;
                    
                    cursor: pointer;

                        &._close {
                            background-image: url(__static__content/form-close.svg);
                            background-size: 14px;
                        }
                }

        &__users {
            padding-left: 119+40px;
        }

            &__user {
                display: flex;
                align-items: center;
                margin-bottom: 8px;

                cursor: pointer;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    &:hover, &._active {

                            .create-chat__user-name {
                                color: #1e1e24;
                                
                                border-radius: 5px 0 0 5px;
                                background-color: #f5f5fa;
                            }
                    }
            }

                &__user-avatar {
                    width: 40px;
                    height: 40px;
                    margin-right: 24px;

                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: cover;
                }

                &__user-name {
                    width: 100%;
                    padding-top: 18px;
                    padding-bottom: 17px;
                    padding-left: 16px;

                    font-weight: 500;
                    color: #000000;

                    transition: .3s;
                }
}
