@font-face {
    font-family: 'SFUI Display';
    font-weight: 500;
    src: url('../fonts/SFUIDisplayMedium.eot');
    src: url('../fonts/SFUIDisplayMedium.eot') format('embedded-opentype'),
         url('../fonts/SFUIDisplayMedium.woff2') format('woff2'),
         url('../fonts/SFUIDisplayMedium.woff') format('woff'),
         url('../fonts/SFUIDisplayMedium.ttf') format('truetype'),
         url('../fonts/SFUIDisplayMedium.svg#SFUIDisplayMedium') format('svg');
}

@font-face {
    font-family: 'SFUI Display';
    font-weight: 700;
    src: url('../fonts/SFUIDisplayBold.eot');
    src: url('../fonts/SFUIDisplayBold.eot') format('embedded-opentype'),
         url('../fonts/SFUIDisplayBold.woff2') format('woff2'),
         url('../fonts/SFUIDisplayBold.woff') format('woff'),
         url('../fonts/SFUIDisplayBold.ttf') format('truetype'),
         url('../fonts/SFUIDisplayBold.svg#SFUIDisplayBold') format('svg');
}

@font-face {
    font-family: 'SFUI Display';
    font-weight: 400;
    src: url('../fonts/SFUIDisplaySemibold.eot');
    src: url('../fonts/SFUIDisplaySemibold.eot') format('embedded-opentype'),
         url('../fonts/SFUIDisplaySemibold.woff2') format('woff2'),
         url('../fonts/SFUIDisplaySemibold.woff') format('woff'),
         url('../fonts/SFUIDisplaySemibold.ttf') format('truetype'),
         url('../fonts/SFUIDisplaySemibold.svg#SFUIDisplaySemibold') format('svg');
}

$SFUI: 'SFUI Display';