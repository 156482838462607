.notification {
    display: flex;
    padding-right: 49px;
    margin-bottom: 23px;

    border-bottom: 1px solid #f0f0f5;

        &:last-child {
            margin-bottom: 0;
            
            border-bottom: none;
        }

        &._friend {

                .notification__body {
                    width: 210px;
                }
        }

        &__body {
            padding-bottom: 20px;
        }

            &__title {
                display: block;
                margin-bottom: 10px;

                color: #1e1e24;
                font-size: 15px;
                font-weight: 500;
            }

            &__desc {
                margin-bottom: 15px;

                color: #9e9ea3;
                line-height: 18px;

                    p {
                        margin: 0;
                    }

                    a {
                        color: #64646d;
                        text-decoration: none;
                    }
            }

            &__buttons {
                display: flex;
            }

                &__button {
                    margin-right: 10px;
                    padding-left: 18px;
                    padding-right: 18px;

                        &:last-child {
                            margin-right: 0;
                        }
                }

        &__avatar {
            position: absolute;
            top: 5px;
            right: 30px;

            width: 46px;
            height: 46px;
            margin-left: 44px;

            text-decoration: none;

            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
        }
}
