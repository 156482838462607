.messages-attach {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 36px;
    padding-left: 33px;

    margin-top: 18px;

        &__item {
            margin-right: 10px;
            width: 100px;
            height: 85px;

            border-radius: 6px;
            border: 1px solid #f7f7fc;
            background-color: #eaeaf3;

            cursor: pointer;

                &._doc {
                    padding-top: 10px;
                    padding-left: 10px;
                    padding-right: 19px;
                    padding-bottom: 12px;
                }

                &:last-child {
                    margin-right: 0;
                }
        }

            &__item-title {
                display: block;
                margin-bottom: 20px;

                line-height: 13px;
                font-size: 11px;
                color: #64646d;
            }

            &__item-format {
                color: #9e9ea3;
                font-size: 16px;
            }

            &__item-photo {
                display: block;
                width: 100%;
                height: 100%;

                text-decoration: none;

                border-radius: 6px;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;

                        border-radius: 6px;

                        object-fit: cover;
                    }
            }

            &__item-video {
                width: 100%;
                height: 100%;

                background-size: cover;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                border-radius: 6px;

                overflow: hidden;

                    &:before {
                        content: '';

                        position: absolute;
                        left: 0;
                        top: 0;
                        bottom: 0;

                        width: 24px;

                        background-color: #1e1e24;
                        opacity: 0.16;
                    }
            }

            &__item-percent {
                z-index: 5;
                position: relative;

                display: block;
                padding-left: 11px;
                padding-top: 59px;

                font-size: 16px;
                font-weight: 700;
                color: #ffffff;
            }
}
