.settings {
    padding-top: 36px;
    padding-bottom: 43px;
    padding-left: 189px;

    border: 1px solid #f7f7fc;
    border-radius: 3px;

        &__form {

        }

            &__title {
                display: block;
                margin-bottom: 50px;

                color: #1e1e24;
                font-size: 19px;
            }

            &__content {

            }

                &__items {
                    padding-right: 149px;
                    padding-bottom: 30px;
                    margin-bottom: 40px;

                    border-bottom: 1px solid #f2f2f7;

                        &:last-child {
                            margin-bottom: 0;
                            padding-bottom: 0;

                            border-bottom: none;
                        }
                }

                    &__items-title {
                        position: absolute;
                        left: -170px;
                        top: 2px;

                        display: block;
                        width: 100px;

                        text-align: right;
                        font-size: 12px;
                        text-transform: uppercase;
                        color: #bcbcc2;
                    }

                    &__items-subtitle {
                        display: block;
                        margin-bottom: 38px;

                        color: #1e1e24;
                    }

                    &__actions {
                        margin-bottom: 40px;
                    }

                    &__notifications {

                    }
}
