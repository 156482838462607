.dialogs {
    padding-bottom: 50px;

    border: 1px solid #f7f7fc;
    border-radius: 3px;

        &__body {
            margin-top: 40px;
        }
}
