.attach {


        &__items {
            display: flex;
            justify-content: space-between;

            border-bottom: 1px solid #f1f1f6;
        }

            &__item {
                width: 100%;
                padding-top: 18px;
                padding-left: 20px;
                padding-bottom: 19px;

                border-right: 1px solid #f1f1f6;
                background-repeat: no-repeat;
                background-position: 50% 60%;

                    &._desc {
                        padding-left: 40px;
                        padding-top: 20px;

                            .attach__item-title {
                                color: #1e1e24;
                            }
                    }

                    &._photo {
                        background-image: url(__static__content/attach-icon-photo.png);
                        background-size: 44px 36px;
                    }

                    &._video {
                        background-image: url(__static__content/attach-icon-video.png);
                        background-size: 32px 36px;
                    }
            }

                &__item-title {
                    color: #64646d;
                }

                &__item-desc {
                    margin-top: 33px;
                    width: 120px;

                    color: #9e9ea3;
                    line-height: 16px;
                    font-size: 12px;

                        p {
                            margin: 0;
                        }
                }

                &__item-title {

                }

        &__files {
            padding-left: 27px;
            padding-right: 27px;
            padding-top: 32px;
            padding-bottom: 123px;
        }

            &__files-container {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                min-height: 251px;

                border-radius: 12px;
                border: 2px dashed #dfdfe6;

                    &._added {
                        border-color: transparent;
                    }
            }

                &__files-content {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                }

                    &__file-title {
                        display: block;
                        margin-bottom: 10px;

                        font-weight: 400;
                        font-size: 24px;
                        color: #9e9ea3;
                    }

                    &__file-subtitle {
                        color: #9e9ea3;

                            b {
                                color: #848488;
                            }
                    }

                    &__file-label {
                        z-index: 10;
                        position: absolute;

                        width: 100%;
                        height: 100%;

                        transition: .3s;
                    }
}
