.form {
    position: relative;

    padding-bottom: 16px;
    height: 48+16px;

        &._users, &._friends {
            margin: 15px;
            margin-bottom: 0;
        }

        &._message {
            z-index: -20;
            position: absolute;
            left: 110px;
            top: 50%;

            width: 450px;
            padding-bottom: 0;
            height: 42px;
            margin-top: -21px;

            opacity: 0;
            
            transition: .3s;

            pointer-events: none;

                &._active {
                    z-index: 20;

                    opacity: 1;

                    pointer-events: all;
                }
            
                .form__search {
                    height: 42px;

                        &:focus {
                            background-color: #fff;
                        }
                }
        }

        &._dialogs {
            z-index: -10;
            position: absolute;
            left: 96px;
            top: -1px;

            width: 430px;
            opacity: 0;

            transition: .3s;

                &._active {
                    z-index: 10;

                    opacity: 1;
                }

                .form__search {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    height: 42px;

                    color: #1e1e24;
                    font-weight: 500;
                }
        }

        &__container {

            &._fixed {
                z-index: 15;
                position: fixed;
                top: 117px;

                width: 638px;
                padding-bottom: 0;

                border-bottom: none;
            }
        }

        &__search {
            height: 49px;
        }

        &__submit {

        }
        
        &__submit-label {
            position: absolute;
            top: 50%;
            right: 14px;

            display: block;
            width: 20px;
            height: 20px;
            margin-top: -10px;

            background-image: url(__static__content/search-icon.png);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            
            cursor: pointer;
        }
}
