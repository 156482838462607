.important {
    padding-bottom: 50px;

    border-radius: 3px;
    border: 1px solid #f7f7fc;
    background-color: #ffffff;

        &__items {
            padding-top: 40px;
            padding-left: 78px;
        }
}
