.settings-action {
    display: flex;
    justify-content: space-between;
    padding-bottom: 14px;
    margin-bottom: 22px;

    border-bottom: 1px solid #f2f2f7;

        &:last-child {
            margin-bottom: 0;
        }

        &._active {

                .settings-action__title {
                    color: #64646d;
                }

                .settings-action__radio {
                    border-color: $main-color;

                        &:before {
                            left: 15px;

                            background-color: $main-color;
                        }
                }
        }

        &__title {
            color: #bcbcc2;

            transition: .3s;
        }

        &__radio {
            width: 32px;
            height: 20px;

            border-radius: 10px;
            border: 2px solid #dadae0;

            transition: .3s;

            cursor: pointer;

                &:before {
                    content: '';

                    position: absolute;
                    top: 50%;
                    left: 3px;

                    display: block;
                    width: 10px;
                    height: 10px;
                    margin-top: -5px;

                    background-color: #dadae0;
                    border-radius: 50%;

                    transition: .3s;
                }
        }
}
