// Common styles of the project

html, body {
    height: 100%;

        &._no-scroll {
            overflow: hidden;
        }
}

.page {
    min-width: 1366px;
    padding-right: 158px;
    
    font-size: 13px;
    font-weight: 400;
    font-family: $SFUI;
}

.main-container {
    z-index: 5;
    display: flex;
    width: 1118px;
    justify-content: space-between;
}

.content {
    width: 670px;
    margin-left: 319+129px;

        &._inside {
            margin-top: 26px;
            padding-bottom: 54px;
        }
}

.add-application {
    position: relative;

    display: block;
    height: 30px;
    padding-left: 48px;
    padding-top: 8px;

    text-decoration: none;
    color: #1e1e24;

    transition: .3s;

        &:before {
            content: '';

            position: absolute;
            left: 0;
            top: 0;

            display: block;

            width: 30px;
            height: 30px;

            border-radius: 50%;
            overflow: hidden;
            background-color: $main-color;
            background-image: url(__static__content/plus-icon.png);
            background-repeat: no-repeat;
            background-position: 50% 50%;
        }

        &:hover {
            padding-left: 54px;
        }
}

.search {
    display: block;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 14px;
    padding-left: 13px;

    color: #c4c4cc;
    
    border: none;
    border-radius: 3px;
    background-color: #f7f7fc;
    border: 2px solid rgba(208,208,217,0);

    transition: .3s;

        &:focus {
            border: 2px solid rgba(208,208,217,1);
        }
}

.fancybox-bg {
    background-color: #e2e2e8;
}

.fancybox-is-open .fancybox-bg {
    opacity: .9;
}

.fancybox-slide > * {
    margin: 0;
    padding: 0;
}

.fancybox-container {
    font-family: $SFUI;
}

.fancybox-close-small {
    right: -70px;

    width: 40px;
    height: 40px;

    border-radius: 50%;
    background-image: url(__static__content/close-icon.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: $main-color;
    overflow: hidden;

    transition: .3s;

        &:hover {
            opacity: .92;
        }
}

.fancybox-close-small::after {
    display: none;
}

.bootstrap-tagsinput {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
}

.bootstrap-tagsinput input {
    height: 32px;
    width: 150px;

    border: none;
}

.share-link {
    position: relative;

    display: block;
    padding-left: 38px;

    color: $main-color;
    text-decoration: none;
    font-size: 13px;
    color: #e10a13;

    transition: .3s;

        &:before {
            content: '';

            position: absolute;
            left: 0;
            top: 0;

            display: block;
            width: 18px;
            height: 18px;

            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-image: url(__static__content/share-icon.svg);

            transition: .3s;
        }

        &:hover {

                &:before {
                    left: -3px;
                }
        }

        &._portfolio {
            padding-left: 0;

                &:hover {

                        &:before {
                            left: 0;
                        }
                }
        }
}

.views {
    position: relative;

    display: block;
    padding-left: 29px;

    font-size: 12px;
    color: #56565c;

        &:before {
            content: '';

            position: absolute;
            left: 0;
            top: -1px;

            display: block;
            width: 22px;
            height: 15px;

            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-image: url(__static__content/eye-icon.svg);
        }
}

.button {
    display: block;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 23px;
    padding-right: 23px;

    color: #ffffff;
    text-decoration: none;

    border-radius: 5px;
    background-color: $main-color;
    border: none;

    cursor: pointer;
    transition: .3s;

        &:hover {
            opacity: .92;
        }

        &._gray {
            color: #56565c;
            background-color: #f0f0f5;

                &:hover {
                    color: #4c4c52;
                    
                    background-color: #e8e8ed;
                    opacity: 1;
                }
        }

        &._cancel {
            margin-right: 0;
        }

        &._disabled {
            color: #bbbbc5;

            background-color: #f7f7fa;

            pointer-events: none;
        }
}

.pop-up {
    display: none;
    margin-top: 54px;
    margin-bottom: 54px;

    overflow: unset;

        .event-full__desc {
            font-size: 13px;
        }

        .user-answer__desc {
            // font-size: 13px; // if nedeed
        }
}

.fancybox-navigation {
    height: 100%;
}

.fancybox-button--share {
    display: none;
}

.back-button {
    position: relative;

    display: block;
    padding-top: 13px;
    padding-bottom: 11px;
    padding-left: 16+40px;

    text-decoration: none;
    color: #848488;

    transition: .3s;

        &:before {
            content: '';

            position: absolute;
            left: 0;
            top: 0;

            display: block;
            width: 40px;
            height: 40px;

            border-radius: 50%;
            background-color: #f7f7fa;
            background-image: url(__static__content/back-arrow.svg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: 15px 12px;

            transition: .3s;
        }

        &:hover {
            color: #64646d;

                &:before {
                    background-image: url(__static__content/back-arrow-hover.svg);
                    background-color: #e8e8eb;
                }
        }

        &._hide {
            color: transparent;

                &:hover {
                    color: transparent;
                }
        }
}

.dz-success-mark, .dz-error-message, .dz-error-mark, .dz-details {
    display: none;
}

.dz-image {
    width: 301px;
    height: 341px;

    border: 1px solid #f7f7fc;
    background-color: #f1f1f6;
    border-radius: 6px;

        img {
            display: block;
            width: 100%;
            height: 100%;

            border-radius: 6px;

            object-fit: cover;
        }
}

.dz-preview {
    display: inline-flex;
    margin-right: 8px;
    margin-bottom: 8px;

        &:nth-child(n+2) {
            margin-right: 0;
        }
}

.dz-drag-hover {
    background-color: rgba(0,0,0,.05);
}