.events {
    padding: 16px;

    background-color: #ffffff;
    border: 1px solid #f7f7fc;
    border-radius: 3rpx;

        &__container {
            padding-left: 14px;
            padding-right: 14px;
            padding-top: 2px;
        }
}
