.edit {
    padding-top: 36px;
    padding-bottom: 43px;
    padding-left: 189px;

    border: 1px solid #f7f7fc;
    border-radius: 3px;

        &__form {

        }

            &__title {
                display: block;
                margin-bottom: 50px;

                color: #1e1e24;
                font-size: 19px;
            }

            &__content {

            }

                &__item {
                    padding-right: 149px;
                    padding-bottom: 29px;
                    margin-bottom: 40px;

                    border-bottom: 1px solid #f2f2f7;

                        &:last-child {
                            margin-bottom: 0;
                            padding-bottom: 0;

                            border-bottom: none;
                        }
                }

                    &__item-title {
                        position: absolute;
                        left: -170px;
                        top: 3px;

                        display: block;
                        width: 100px;

                        text-align: right;
                        font-size: 12px;
                        text-transform: uppercase;
                        color: #bcbcc2;
                    }

                    &__name {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 29px;
                    }

                        &__name-item {
                            width: 160px;
                        }

                            &__item-subtitle {
                                display: block;
                                margin-bottom: 8px;

                                color: #b3b3ba;

                                    b {
                                        font-weight: 400;
                                        color: #64646d;
                                    }
                            }

                            &__name-value, &__date-item, &__dropdown-item, &__skills-input, &__contact-input, &__price-input, &__social-input {
                                display: block;
                                width: 100%;
                                padding-left: 13px;
                                padding-top: 16px;
                                padding-bottom: 16px;

                                color: #1e1e24;

                                background-color: #f7f7fc;
                                border-radius: 5px;
                                border: none;
                            }

                    &__date {
                        margin-bottom: 30px;
                    }

                        &__date-items {
                            display: flex;
                            justify-content: space-between;
                        }

                            &__date-item {
                                margin-right: 10px;

                                transition: .3s;

                                cursor: pointer;

                                    &._day {
                                        width: 70px;
                                    }

                                    &._month {
                                        width: 150px;
                                    }

                                    &._year {
                                        width: 90px;
                                    }

                                    &._active {
                                        border-bottom-right-radius: 0;
                                        border-bottom-left-radius: 0;
                                    }

                                    &:after {
                                        content: '';

                                        position: absolute;
                                        right: 14px;
                                        top: 50%;

                                        display: block;
                                        width: 8px;
                                        height: 4px;
                                        margin-top: -2px;

                                        background-position: 50% 50%;
                                        background-image: url(__static__content/more-arrow-input.svg);
                                        background-repeat: no-repeat;
                                    }
                            }

                                &__date-value {

                                }

                                &__dropdown {
                                    z-index: -10;
                                    position: absolute;
                                    top: 100%;
                                    left: 0;
                                    right: 0;

                                    opacity: 0;

                                    transition: .3s;

                                        &._active {
                                            z-index: 10;

                                            opacity: 1;
                                        }
                                }

                                    &__dropdown-item {
                                        border-radius: 0;

                                        cursor: pointer;
                                    }

                    &__gender {
                        margin-bottom: 29px;
                    }

                        &__gender-items {
                            display: flex;
                        }

                            &__gender-item {
                                display: inline-block;
                                vertical-align: top;
                                width: 50%;
                                padding-top: 16px;
                                padding-bottom: 16px;

                                text-align: center;
                                color: #7d7d89;

                                background-color: #f0f0f5;

                                transition: .3s;

                                cursor: pointer;

                                    &:first-of-type {
                                        border-radius: 5px 0 0 5px;
                                    }

                                    &:last-child {
                                        border-radius: 0 5px 5px 0;
                                    }

                                    &._active {
                                        color: #fff;

                                        background-color: $main-color;
                                    }
                            }

                    &__desc {
                        margin-bottom: 30px;
                    }

                        &__desc-container {
                            overflow: hidden;
                            width: 330px;
                        }

                            &__desc-textarea {
                                display: block;
                                width: 110%;
                                height: 146px;
                                padding-bottom: 11px;
                                padding-top: 10px;
                                padding-left: 12px;
                                padding-right: 50px;

                                color: #1e1e24;
                                line-height: 18px;

                                border-radius: 5px;
                                background-color: #f7f7fc;
                                border: none;

                                resize: none;
                            }

                    &__skills {

                    }

                        &__skills-container {
                            margin-bottom: 16px;

                                &:after {
                                    content: '';

                                    position: absolute;
                                    right: 18px;
                                    top: 50%;

                                    display: block;
                                    width: 8px;
                                    height: 4px;
                                    margin-top: -2px;

                                    background-position: 50% 50%;
                                    background-image: url(__static__content/more-arrow-input.svg);
                                    background-repeat: no-repeat;
                                }
                        }

                            &__skills-input {
                                cursor: pointer;

                                    &._active {
                                        border-bottom-right-radius: 0;
                                        border-bottom-left-radius: 0;
                                    }
                            }

                        &__tags {
                            display: flex;
                            flex-wrap: wrap;
                        }

                            &__tag {
                                margin-right: 6px;
                                margin-bottom: 6px;
                            }

                    &__contacts {

                    }

                        &__contact {
                            margin-bottom: 18px;

                                &:last-child {
                                    margin-bottom: 0;
                                }
                        }

                            &__contact-container {

                            }

                                &__contact-input {

                                }

                    &__price {

                    }

                        &__price-container {
                            padding-right: 90px;

                                &:after {
                                    content: 'рублей';

                                    position: absolute;
                                    right: 14px;
                                    top: 16px;

                                    color: #868693;
                                }
                        }

                            &__price-input {

                            }

                        &__price-link {
                            display: block;
                            margin-top: 27px;

                            text-decoration: none;
                            color: #64646d;
                        }

                    &__social {
                        margin-bottom: 29px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                    }

                        &__social-container {

                        }

                            &__social-input {

                            }
}
