.application {
    z-index: -10;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;

    width: 728px;
    height: 100%;

    background-color: #fff;
    opacity: 0;

    transform: translateX(-100%);
    transition: transform .3s cubic-bezier(1,0,0,1);

    overflow: hidden;

        &._active {
            z-index: 100001;
            
            opacity: 1;

            transform: translateX(0);
        }

        &__container {
            height: 100%;
            width: 744px;

            padding-right: 200px;
            padding-left: 167px;
            padding-top: 35px;

            overflow-y: scroll;
        }

            &__title {
                display: block;
                margin-bottom: 70px;

                color: #2a2a34;
                text-transform: uppercase;
                line-height: 27px;
                font-size: 24px;
            }

            &__close {
                position: absolute;
                right: 50px;
                top: 26px;

                width: 40px;
                height: 40px;

                border-radius: 50%;
                background-image: url(__static__content/close-icon.svg);
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-color: $main-color;
                overflow: hidden;

                transition: .3s;

                    &:hover {
                        opacity: .92;
                    }
            }

            &__form {
                padding-bottom: 36px;
            }

                &__list {
                    @include nl;

                    padding-bottom: 22px;
                    margin-bottom: 28px;

                    border-bottom: 1px solid #f2f2f5;

                    counter-reset: item;
                }

                    &__item {
                        position: relative;

                        margin-bottom: 65px;
                        width: 360px;

                            &:before {
                                content: "0" counter(item) ".";
                                counter-increment: item;

                                position: absolute;
                                top: 0;
                                left: -89px;

                                font-size: 15px;
                                color: #9595a2;
                            }

                            &:last-child {
                                margin-bottom: 0;
                            }

                            &._skill {

                                    .application__dropdown {
                                        display: block;
                                        width: 100%;
                                        padding-left: 14px;
                                        padding-top: 7px;
                                        padding-bottom: 7px;

                                        font-size: 13px;
                                        color: #1e1e24;
                                        font-weight: 500;

                                        border: 1px solid #e6e6eb;
                                        border-radius: 4px;
                                    }
                            }
                    }

                        &__subtitle {
                            display: block;
                            margin-bottom: 27px;

                            color: #4e4e58;
                            font-size: 15px;
                        }

                        &__public-title {
                            display: block;
                            margin-bottom: 23px;

                            color: #68686d;
                        }

                        &__radio {

                                &:checked {

                                    & + label {
                                        color: #fff;

                                        background-color: $main-color;
                                    }
                                }
                        }

                        &__radio-label {
                            display: inline-block;
                            vertical-align: top;
                            width: 180px;
                            padding-top: 18px;
                            padding-bottom: 19px;

                            text-align: center;
                            color: #7d7d89;

                            background-color: #f0f0f5;

                            transition: .3s;

                            cursor: pointer;

                                &:first-of-type {
                                    margin-right: -3px;

                                    border-radius: 5px 0 0 5px;
                                }

                                &:last-child {
                                    border-radius: 0 5px 5px 0;
                                }
                        }

                        &__input, &__textarea {
                            display: block;
                            width: 100%;
                            padding-left: 14px;

                            font-size: 13px;
                            color: #1e1e24;
                            font-weight: 500;

                            border: 1px solid #e6e6eb;
                            border-radius: 4px;

                                &:focus {
                                    padding-left: 13px;

                                    border: 2px solid #d0d0d9;
                                }
                        }

                        &__input-title {
                            display: block;
                            margin-bottom: 13px;
                            margin-top: 22px;

                            color: #b3b3ba;
                        }

                        &__input {
                            padding-top: 15px;
                            padding-bottom: 14px;

                                &:focus {
                                    padding-top: 14px;
                                    padding-bottom: 13px;
                                }

                                &._dropdown {
                                    background-image: url(__static__content/more-arrow-input.svg);
                                    background-repeat: no-repeat;
                                    background-position: 334px 50%;
                                }

                                &._small {
                                    display: inline-block;
                                    vertical-align: top;
                                    width: 175px;
                                    margin-right: 7px;
                                    padding-left: 55px;

                                    background-repeat: no-repeat;

                                        &:last-child {
                                            margin-right: 0;
                                        }

                                        &:focus {
                                            padding-left: 54px;
                                        }
                                }

                                &._date {
                                    background-position: 15px 50%;
                                    background-image: url(__static__content/calendar-icon.png);
                                }

                                &._time {
                                    background-position: 16px 50%;
                                    background-image: url(__static__content/time-icon.png);
                                }
                        }

                        &__textarea {
                            height: 84px;
                            margin-top: 14px;
                            padding-top: 10px;
                            padding-bottom: 13px;
                            padding-right: 30px;

                            line-height: 16px;

                            resize: none;

                                &:focus {
                                    padding-top: 9px;
                                    padding-bottom: 12px;
                                }
                        }

                    &__dropdown {

                    }

                        &__no-skill {
                            position: absolute;
                            right: -105px;
                            top: 19px;

                            display: block;

                            text-decoration: none;
                            color: #5f5f6b;
                            font-size: 12px;
                            font-weight: 400;
                            
                            transition: .3s;

                                &:hover {
                                    color: #e10a13;
                                }
                        }

                        &__dropdown-items {
                            z-index: -10;
                            position: absolute;
                            top: 100%;
                            left: 0;
                            right: 0;

                            opacity: 0;
                            background-color: #fff;
                            border: 2px solid rgba(208,208,217,1);
                            border-top: none;
                            border-bottom-right-radius: 4px;
                            border-bottom-left-radius: 4px;

                                &._active {
                                    z-index: 15;

                                    opacity: 1;
                                }
                        }

                            &__dropdown-item {
                                display: block;
                                padding-left: 14px;
                                padding-top: 10px;
                                padding-bottom: 13px;

                                border-bottom: 2px solid rgba(208,208,217,1);

                                transition: .3s;
                                cursor: pointer;

                                    &:last-child {
                                        border-bottom: none;
                                    }

                                    &:hover {
                                        background-color: rgba(208,208,217,.3);
                                    }
                            }

                    &__tags {
                        display: flex;
                        flex-wrap: wrap;
                        margin-top: 16px;
                        margin-bottom: 24px;
                    }

                        &__tag {
                            margin-right: 6px;
                            margin-bottom: 6px;

                            cursor: pointer;

                                &:last-child {
                                    margin-right: 0;
                                }
                        }

                    &__price-input {
                        padding-right: 90px;

                            &:after {
                                content: 'рублей';

                                position: absolute;
                                right: 16px;
                                top: 16px;

                                color: #868693;
                            }
                    }

                &__submit {
                    padding-top: 16px;
                    padding-bottom: 15px;
                    padding-left: 18px;
                    padding-right: 18px;

                    color: #ffffff;
                    font-weight: 500;

                    background-color: $main-color;
                    border: none;
                    border-radius: 5px;
                }
}
