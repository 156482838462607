.settings-item {
    margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;
        }

        &__title {
            display: block;
            margin-bottom: 8px;

            color: #b3b3ba;
        }

        &__container {
            display: flex;
            justify-content: space-between;
            padding-top: 9px;
            padding-bottom: 9px;
            padding-right: 9px;
            padding-left: 12px;

            border: 1px solid #e6e6eb;
            border-radius: 5px;
        }

            &__value {
                display: block;
                margin-top: 9px;

                color: #1e1e24;
            }

                &__change {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    padding-left: 14px;
                    padding-right: 14px;

                    color: #56565c;
                    font-size: 12px;
                    text-decoration: none;
                    
                    background-color: #f5f5f7;
                    border-radius: 5px;

                    transition: .3s;

                        &:hover {
                            color: #4c4c52;
                            
                            background-color: #e8e8ed;
                        }
                }
}
