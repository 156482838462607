.filter-button {
    position: fixed;
    left: 1208px;
    top: 26px;

    display: block;
    padding-top: 54px;

    text-decoration: none;

        &._messages {

                &:before {
                    background-image: url(__static__content/filter-dialog.svg);
                }
        }

        &._dialog {
            padding-top: 85px;

                &:before {
                    background-image: url(__static__content/filter-dialog.svg);
                }

                .filter-button__title {
                    transform: rotate(90deg) translateY(32px);
                }

                &._active {

                        &:before {
                            transform: rotate(45deg);
                        }
                }
        }

        &:hover {

                .filter-button__title {
                    margin-top: 20px;
                }
        }

        &:before {
            content: '';

            position: absolute;
            top: 0;
            left: 0;

            display: block;
            width: 40px;
            height: 40px;

            border-radius: 50%;
            background-color: #ed3c20;
            background-image: url(__static__content/filter-icon.svg);
            background-repeat: no-repeat;
            background-position: 48% 50%;

            overflow: hidden;
            
            transition: .3s;
        }

        &__title {
            display: block;
            margin-top: 14px;

            color: #848488;
            font-size: 12px;
            text-transform: uppercase;

            transform: rotate(90deg) translateY(4px);
            transition: .3s;
        }
}
