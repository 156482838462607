.user {
    display: flex;
    justify-content: space-between;
    padding-top: 24px;

    transition: .3s;

        &:hover {
            padding-left: 10px;

                .user__info {
                    padding-left: 10px;
                }

                .user__name {
                    padding-top: 3px;
                    opacity: .86;
                }

                .user__grade {
                    z-index: -10;

                    opacity: 0;
                }

                .user__buttons {
                    z-index: 10;

                    opacity: 1;
                }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &__avatar {
            width: 80px;
            height: 80px;

            text-decoration: none;

            border-radius: 50%;
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &__info {
            width: 530px;
            padding-top: 2px;
            padding-bottom: 28px;
            padding-right: 29px;

            transition: .3s;

                &:after {
                    content: '';

                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 530px;

                    height: 1px;

                    background-color: #f1f1f6;
                }
        }

            &__city {
                display: block;
                margin-bottom: 4px;

                color: #a9a9af;
            }

            &__name {
                display: block;
                margin-bottom: 5px;

                color: #1e1e24;
                font-size: 19px;
                text-decoration: none;

                transition: .3s;
            }

            &__tags {
                display: flex;
                flex-wrap: wrap;
            }

                &__tag {
                    margin-right: 6px;
                }

        &__other {
            position: absolute;
            right: 30px;
            margin-top: 10px;
        }

            &__grade {
                position: absolute;
                top: 0;
                right: 0;

                z-index: 10;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 54px;
                height: 54px;

                color: #ffffff;
                font-size: 17px;
                font-weight: 500;
                text-transform: uppercase;

                opacity: 1;
                border-radius: 50%;
                background-color: #56bf36;

                transition: .3s;

                    &._color-1 {
                        color: rgba(0,0,0,.63);
                        
                        background-color: #ff5f40;
                    }

                    &._color-2 {
                        color: rgba(0,0,0,.63);
                        
                        background-color: #ffa340;
                    }

                    &._color-3 {
                        color: rgba(0,0,0,.63);

                        background-color: #deef27;
                    }

                    &._color-4 {
                        color: #ffffff;

                        background-color: #86d646;
                    }

                    &._color-5 {
                        color: #ffffff;

                        background-color: #56bf36;
                    }
            }

            &__buttons {
                z-index: -10;
                position: absolute;
                top: 10px;
                right: 0;

                display: flex;

                opacity: 0;

                transition: .3s;
            }

                &__button {
                    display: block;
                    width: 38px;
                    height: 38px;
                    margin-right: 8px;

                    background-color: $main-color;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    border-radius: 50%;

                    transition: .3s;

                        &._add {
                            background-image: url(__static__content/add-icon.png);

                                &:before {
                                    content: '';

                                    position: absolute;
                                    left: -9px;
                                    top: -9px;

                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    width: 18px;
                                    height: 18px;

                                    border: 3px solid #ffffff;
                                    background-color: $main-color;
                                    background-image: url(__static__content/add-plus-icon.png);
                                    background-repeat: no-repeat;
                                    background-position: 50% 50%;
                                    border-radius: 50%;

                                    transition: .3s;
                                }

                                &._sended {
                                    background-color: #c4c4cc;

                                        &:before {
                                            content: '?';

                                            font-size: 11px;
                                            color: #ffffff;

                                            background-image: none;
                                            background-color: #c4c4cc;
                                        }
                                }

                                &._added {
                                    background-color: #c4c4cc;

                                        &:before {
                                            background-image: url(__static__content/added-icon.png);
                                            background-color: #c4c4cc;
                                        }
                                }
                        }

                        &._message {
                            background-image: url(__static__content/message-icon.png);
                        }

                        &:last-child {
                            margin-right: 0;
                        }
                }
}
