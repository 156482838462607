.messages {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

        &__header {
            z-index: 14;
            position: fixed;
            top: 103px;

            display: flex;
            width: 670px;
            justify-content: space-between;
            padding: 18px 23px;
            height: 76px;

            background-color: #fff;
            border: 1px solid #f7f7fc;
            border-bottom: none;
            border-radius: 3px 3px 0 0;
            box-shadow: 0 3px 6px rgba(225, 225, 230, 0.25);

                &:before {
                    content: '';

                    position: fixed;
                    top: 0;

                    height: 103px;
                    width: 670px;
                    margin-left: -24px;
                    
                    background-color: #fff;
                }
        }

        &__body {
            padding-left: 119px;
            padding-right: 119px;
            padding-top: 76+103px;
            padding-bottom: 126px;

            border-radius: 3px 3px 0 0;
            border: 1px solid #f7f7fc;
            background-color: #ffffff;
        }

            &__date-separator {
                display: block;
                margin-top: 40-14px;
                margin-bottom: 27px;

                text-align: center;
                font-weight: 500;
                color: #9e9ea3;
            }

        &__footer {
            z-index: 14;
            position: fixed;
            bottom: 0;

            width: 670px;
            min-height: 78px;
            padding-left: 37px;
            padding-right: 22px;

            background-color: #fff;
        }
}
