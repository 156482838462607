.profile-friends {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 19px;
    padding-left: 12px;
    padding-right: 12px;

        &__item {
            position: relative;

            display: block;

            text-decoration: none;

                &._active {

                        .profile-friends__avatar {
                            width: 80px;
                            height: 80px;
                        }

                        .profile-friends__name {
                            display: block;
                        }
                }
        }

            &__avatar {
                width: 60px;
                height: 60px;

                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                border-radius: 50%;
            }

            &__name {
                position: absolute;
                bottom: -14-11px;
                left: 0;
                right: 0;

                display: none;

                color: #1e1e24;
                text-align: center;
            }
}
