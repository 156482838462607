.change-popup {
    display: none;
    width: 470px;
    padding-top: 25px;

    border-radius: 12px;
    background-color: #ffffff;

        &__main {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 26px;

            border-bottom: 1px solid #f1f1f6;
        }

            &__title {
                display: block;
                margin-bottom: 20px;
            }

            &__desc {
                margin-bottom: 24px;

                color: #797979;
                line-height: 17px;
                font-weight: 500;

                    p {
                        margin: 0;
                    }
            }

            &__input {
                display: block;
                width: 100%;
                padding-left: 14px;
                padding-top: 15px;
                padding-bottom: 14px;

                font-size: 13px;
                color: #1e1e24;
                font-weight: 500;

                border: 1px solid #e6e6eb;
                border-radius: 4px;

                    &._gray {
                        margin-top: 14px;
                        
                        background-color: #f7f7fc;
                    }

                    &:focus {
                        padding-left: 13px;
                        padding-top: 14px;
                        padding-bottom: 13px;

                        border: 2px solid #d0d0d9;
                        background-color: #fff;
                    }
            }

        &__buttons {
            display: flex;
            justify-content: space-between;
            padding: 16px 30px;
        }

            &__button {

            }
}
