.profile-line {
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    padding-bottom: 15px;

    border-bottom: 1px solid  #f5f5fa;

        &__main {
            text-decoration: none;
            color: #1e1e24;

                &:hover {

                        .profile-line__name {
                            padding-left: 6px;
                        }
                }
        }

            &__icon {
                display: inline-block;
                vertical-align: top;
                margin-right: 18-4px;
                width: 30px;
                height: 30px;

                border-radius: 50%;
                background-color: #dfdada;

                overflow: hidden;

                background-image: url(__static__/content/profile-icon.png);
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
            }

            &__name {
                display: inline-block;
                vertical-align: top;
                margin-top: 6px;

                transition: .3s;
            }

        &__other {
            display: flex;
            justify-content: space-between;
            width: 59px;
            padding-top: 4px;
        }

            &__settings {
                display: block;
                width: 21px;
                height: 21px;

                text-decoration: none;

                transition: .3s ease-in;

                    use {
                        fill: #bcbcc2;

                        transition: .3s;
                    }

                    &:hover {
                        transform: rotate(90deg);

                        use {
                            fill: #a4a4ab;
                        }
                    }
            }

            &__notifications {
                position: relative;

                display: block;
                width: 15px;
                height: 18px;

                text-decoration: none;

                transition: .3s ease-in;

                    use {
                        fill: #bcbcc2;

                        transition: .3s;
                    }

                    &:hover {

                        use {
                            fill: #a4a4ab;
                        }
                    }

                    &:after {
                        content: '';

                        position: absolute;
                        top: 0;
                        right: -6px;

                        display: block;
                        width: 6px;
                        height: 6px;

                        opacity: 0;
                        border-radius: 50%;
                        background-color: $main-color;
                        border: 2px solid #ffffff;
                        overflow: hidden;

                        transition: .3s;
                    }

                    &._active {

                            &:after {
                                opacity: 1;
                            }
                    }
            }
}
