.event-customer {
    display: flex;
    justify-content: space-between;

        &__info {
            text-align: right;
        }

            &__title {
                display: block;
                color: #848488;
            }

            &__other {
                margin-right: 20px;
            }

                &__grade, &__name {
                    display: inline-block;
                    vertical-align: top;
                }

                &__grade {
                    margin-right: 7px;

                    color: #71bd35;
                    font-weight: 500;

                        &._color-1 {
                            color: #ff5f40;
                        }

                        &._color-2 {
                            color: #ffa340;
                        }

                        &._color-3 {
                            color: #deef27;
                        }

                        &._color-4 {
                            color: #86d646;
                        }

                        &._color-5 {
                            color: #56bf36;
                        }
                }

                &__name {
                    color: #1e1e24;
                }

        &__avatar {
            width: 36px;
            height: 36px;

            border-radius: 50%;
            overflow: hidden;
            background-image: url(__static__content/event-customer.png);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
            background-color: #dfdfe6;
        }
}
