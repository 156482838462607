.violator {
    width: 470px;

    border-radius: 12px;

        &__header {
            padding: 24px 30px;
        }

            &__title {
                display: block;
                margin-bottom: 33px;

                color: #1e1e24;
            }

            &__user {
                display: flex;
                align-items: center;
            }

                &__avatar {
                    width: 46px;
                    height: 46px;
                    margin-right: 20px;

                    text-decoration: none;

                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: cover;
                }

                &__info {

                }

                    &__info-title {
                        display: block;
                        margin-bottom: 1px;

                        color: #848488;
                    }

                    &__name {
                        color: #1e1e24;

                        text-decoration: none;
                    }

        &__content {
            padding-left: 30px;
            padding-bottom: 3px;

            border-top: 1px solid #f1f1f6;
        }

            &__item {
                display: flex;
                justify-content: space-between;
                padding-top: 24px;
            }

                &__item-date {
                    color: #bcbcc2;
                }

                &__item-content {
                    width: 320px;
                    padding-bottom: 22px;
                    padding-right: 13px;

                    border-bottom: 1px solid #f5f5fa;
                }

                    &__item-header {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 12px;
                        width: 200px;
                    }

                        &__item-title {
                            color: #64646d;
                        }

                        &__item-subtitle {
                            color: #848488;
                        }

                    &__item-desc {
                        line-height: 17px;
                        color: #848488;

                            p {
                                margin: 0;
                            }
                    }
}
