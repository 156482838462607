.copyrights {
    color: #bebec2;
    font-size: 12px;
    font-weight: 500;

        span {

        }

        a {
            color: #64646d;
            text-decoration: none;
        }
}
