.work-add {
    padding-top: 16px;

    border-radius: 12px;
    border: 1px solid #f7f7fc;

        &__form {

        }

            &__buttons {
                display: flex;
                justify-content: space-between;
                padding-bottom: 15px;
                padding-left: 29px;
                padding-right: 29px;

                border-bottom: 1px solid #f1f1f6;
            }


                &__button {


                        &._send {

                        }

                        &._disable {

                        }
                }

            &__main {

            }

                &__header {
                    padding-top: 38px;
                    padding-left: 238px;
                    padding-right: 206px;
                    margin-bottom: 27px;
                }

                    &__title {
                        display: block;
                        margin-bottom: 20px;

                        font-size: 24px;
                        font-weight: 700;
                        text-transform: uppercase;
                        line-height: 27px;
                        color: #1e1e24;
                    }

                    &__subtitle {
                        color: #9e9ea3;
                        font-weight: 500;
                        line-height: 17px;
                    }

                &__body {
                    padding-left: 39px;
                    padding-right: 68px;
                    padding-bottom: 38px;
                    
                    border-bottom: 1px solid #f1f1f6;
                }

                    &__item {
                        display: flex;
                        margin-bottom: 18px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                    }

                        &__label {
                            width: 75px;
                            margin-right: 125px;
                            margin-top: 12px;

                            color: #9e9ea3;
                        }

                        &__input, &__textarea {
                            border: 1px solid #e6e6eb;
                            border-radius: 5px;

                                &:focus {
                                    padding-left: 12px;

                                    border: 2px solid #d0d0d9;
                                }
                        }

                        &__input {
                            display: block;
                            padding-top: 16px;
                            padding-bottom: 14px;
                            padding-left: 13px;
                            width: 216px;

                                &:focus {
                                    padding-top: 15px;
                                    padding-bottom: 13px;
                                }
                        }

                        &__textarea {
                            display: block;
                            width: 360px;
                            height: 88px;
                            padding: 12px 13px;

                            resize: none;

                                &:focus {
                                    padding-top: 11px;
                                    padding-bottom: 11px;
                                    padding-right: 12px;
                                }
                        }
}
