.dialog-preview {
    padding-left: 120px;
    margin-bottom: 26px;

    text-decoration: none;

        &:hover {

                .dialog-preview__delete {
                    z-index: 10;

                    opacity: 1;
                }

                .dialog-preview__date {
                    z-index: -10;

                    opacity: 0;
                }
        }

        &:last-child {
            margin-bottom: 0;

                .dialog-preview__body {
                    border-bottom: none;
                }
        }

        &__new-count {
            position: absolute;
            left: 42px;
            top: 4px;

            display: flex;
            align-items: center;
            justify-content: center;
            width: 26px;
            height: 26px;

            color: #ffffff;

            border-radius: 50%;
            background-color: #f20c0c;
        }

        &__body {
            display: flex;
            padding-bottom: 22px;

            border-bottom: 1px solid #f1f1f6;
        }

            &__avatar {
                position: relative;
                
                width: 34px;
                height: 34px;
                margin-right: 46px;

                text-decoration: none;

                background-position: 50% 50%;
                background-size: cover;
                background-repeat: no-repeat;
                border-radius: 50%;
            }

            &__info {
                position: relative;

                width: 270px;
                margin-right: 50px;

                text-decoration: none;
            }

                &__name {
                    display: block;
                    margin-bottom: 3px;

                    color: #1e1e24;
                }

                &__desc {
                    height: 36px;

                    line-height: 18px;
                    color: #505057;
                    font-weight: 500;
                    
                    overflow: hidden;

                        p {
                            margin: 0;
                        }

                        b {
                            color: #000000;
                            font-weight: 400;
                        }
                }

                &__readed {
                    display: block;
                    margin-top: 6px;

                    color: #b1b1b7;
                    font-size: 12px;
                    font-weight: 500;
                }

            &__date {
                z-index: 10;

                color: #b9b9c4;
                font-size: 12px;

                opacity: 1;
                transition: .3s;
            }

            &__delete {
                z-index: -10;
                position: absolute;
                right: 137px;
                top: 0;

                display: block;
                width: 14px;
                height: 14px;

                opacity: 0;
                transition: .3s;

                    use {
                        transition: .3s;
                    }

                    &:before {
                        content: 'Удалить диалог';

                        position: absolute;
                        top: -36px;
                        left: 50%;
                        width: 120px;

                        display: block;
                        padding-top: 8px;
                        padding-bottom: 8px;
                        margin-left: -60px;

                        color: #ffffff;
                        font-weight: 500;
                        font-size: 12px;
                        text-align: center;

                        border-radius: 6px;
                        background-color: rgba(30,30,36,.9);
                        opacity: 0;

                        pointer-events: none;

                        transition: .3s;
                    }

                    &:hover {

                            use {
                                fill: #f20c0c;
                            }

                            &:before {
                                z-index: 10;

                                opacity: 1;
                            }
                    }
            }
}
