.send-message {
    width: 470px;

    border-radius: 12px;

        &__main {
            padding: 25px 30px;
        }

            &__title {
                display: block;
                margin-bottom: 32px;

                color: #1e1e24;
            }

            &__user {
                display: flex;
            }

                &__user-avatar {
                    display: block;
                    width: 46px;
                    height: 46px;
                    margin-right: 20px;

                    text-decoration: none;

                    background-position: 50% 50%;
                    background-size: cover;
                    background-repeat: no-repeat;
                    border-radius: 50%;
                }

                &__user-info {
                    padding-top: 6px;
                }

                    &__user-title {
                        display: block;

                        color: #848488;
                    }

                    &__user-name {
                        display: block;

                        color: #1e1e24;
                        text-decoration: none;
                    }

        &__footer {
            padding: 16px 30px;

            border-bottom: 1px solid #f1f1f6;
            border-top: 1px solid #f1f1f6;
        }

            &__message {

            }

                &__textarea {
                    display: block;
                    width: 100%;
                    padding: 10px 13px;
                    height: 103px;
                    
                    font-weight: 500;
                    line-height: 17px;
                    color: #1e1e24;

                    border: 1px solid #f1f1f6;
                    border-radius: 3px;
                    background-color: #ffffff;

                    resize: none;

                        &:focus {
                            padding: 9px 12px;

                            border: 2px solid #d0d0d9;
                        }
                }

            &__buttons {
                display: flex;
                justify-content: space-between;
                padding-top: 16px;
            }

                &__button {


                        &._gray {
                            
                        }

                        &._disabled {

                        }
                }
}
