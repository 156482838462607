.settings-notification {

        &:first-of-type {

                .settings-notification__title {
                    margin-top: 45px;
                    margin-bottom: 30px;
                }
        }

        &:last-of-type {

                .settings-notification__item {
                    margin-bottom: 0;

                    border-bottom: none;
                }
        }

        &._active {

                .settings-notification__item-title {
                    color: #1e1e24;
                }

                .settings-notification__item-radio {
                    border-color: $main-color;

                        &:before {
                            left: 15px;

                            background-color: $main-color;
                        }
                }
        }

        &__title {
            display: block;

            color: #848488;
        }

        &__item {
            display: flex;
            justify-content: space-between;
            padding-bottom: 19px;
            margin-bottom: 22px;

            border-bottom: 1px solid #f7f7fc;
        }

            &__item-title {
                color: #bcbcc2;

                transition: .3s;
            }

            &__item-radio {
                width: 32px;
                height: 20px;

                border-radius: 10px;
                border: 2px solid #dadae0;

                transition: .3s;

                cursor: pointer;

                transition: .3s;

                    &:before {
                        content: '';

                        position: absolute;
                        top: 50%;
                        left: 3px;

                        display: block;
                        width: 10px;
                        height: 10px;
                        margin-top: -5px;

                        background-color: #dadae0;
                        border-radius: 50%;

                        transition: .3s;
                    }
            }

            &__button {
                color: #e1151e;
                text-decoration: none;

                transition: .3s;
            }
}
