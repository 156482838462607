.dialog-delete {
    display: none;
    width: 470px;
    padding-top: 25px;

    border-radius: 12px;
    background-color: #ffffff;

        &__main {
            padding-left: 30px;
            padding-right: 30px;
            padding-bottom: 26px;

            border-bottom: 1px solid #f1f1f6;
        }

            &__title {
                display: block;
                margin-bottom: 20px;
            }

            &__desc {
                margin-bottom: 24px;

                color: #797979;
                line-height: 17px;
                font-weight: 500;

                    p {
                        margin: 0;
                    }
            }

        &__buttons {
            display: flex;
            justify-content: space-between;
            padding: 16px 30px;
        }

            &__button {

            }
}
