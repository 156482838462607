.my-info {
    display: none;

        &._active {
            display: block;
        }

        &__main {
            padding-top: 25px;
            padding-left: 116px;
        }

            &__header {
                display: flex;
                margin-bottom: 17px;
            }

                &__avatar {
                    display: block;
                    width: 44px;
                    height: 44px;
                    margin-right: 40px;

                    border-radius: 50%;
                    background-repeat: no-repeat;
                    background-position: 50% 50%;
                    background-size: cover;
                }

                &__title {
                    display: block;
                    margin-top: 13px;

                    font-size: 19px;
                    color: #1e1e24;
                }

            &__body {
                padding-left: 84px;
            }

                &__desc {
                    margin-bottom: 23px;
                    width: 349px;

                    color: #505056;
                    line-height: 18px;

                        p {
                            margin: 0;
                        }
                }

                &__items {
                    width: 470px;
                    padding-bottom: 25px;

                    border-bottom: 1px solid #f2f2f7;
                }

                    &__item {
                        display: flex;
                        margin-bottom: 14px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                    }

                        &__item-title {
                            width: 130px;
                            margin-right: 43-13px;

                            color: #848488;
                        }

                        &__item-value {
                            color: #1e1e24;

                            text-decoration: none;

                            transition: .3s;

                                &._social {
                                    color: #e1151e;
                                }
                        }

        &__other {

        }

            &__other-item {
                display: flex;
                padding-top: 40px;
                padding-left: 20px;

                    &:last-child {

                            .my-info__items {
                                border-bottom: none;
                            }
                    }
            }

                &__other-title {
                    width: 100px;
                    margin-right: 79px;
                    padding-top: 3px;

                    font-size: 12px;
                    text-transform: uppercase;
                    color: #bcbcc2;
                    text-align: right;
                }

                &__tags {
                    display: flex;
                    flex-wrap: wrap;
                }

                    &__tag {
                        margin-right: 6px;
                        margin-bottom: 6px;
                    }

                &__prices {

                }

                    &__price {
                        margin-bottom: 19px;

                            &:last-child {
                                margin-bottom: 0;
                            }
                    }

                        &__price-title {
                            display: block;
                            margin-bottom: 5px;

                            color: #1e1e24;
                            font-size: 19px;
                            font-weight: 700;
                        }

                        &__price-subtitle {
                            display: block;

                            color: #848488;
                        }
}
