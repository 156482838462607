.navigation-item {
    margin-right: 50px;

        &:last-child {
            margin-right: 0;
        }

        &._active {

                .navigation-item__link {
                    color: #1e1e24;

                    border-bottom: 2px solid #e1151e;

                        &:hover {
                            color: #1e1e24;
                        }
                }
        }

        &__link {
            display: block;
            padding-left: 9px;
            padding-right: 11px;
            padding-bottom: 20px;

            text-decoration: none;
            color: #848488;

            transition: .3s;

                &:hover {
                    color: #64646d;
                }
        }

            &__count {
                display: inline-block;
                vertical-align: center;
                margin-left: 10px;

                color: #b1b1b7;
                font-size: 12px;
            }
}
