.close-application {
    width: 470px;
    padding-top: 25px;

    background-color: #ffffff;
    border-radius: 12px;


        &__form {

        }

            &__main {
                padding-left: 29px;
                padding-right: 30px;
                padding-bottom: 52px;

                border-bottom: 1px solid #f1f1f6;
            }

                &__title {
                    display: block;
                    margin-bottom: 20px;

                    padding-left: 1px;
                }

                &__desc {
                    margin-bottom: 25px;

                    color: #797979;
                    font-weight: 500;
                    line-height: 17px;

                        p {
                            margin: 0;
                        }
                }

                &__performer {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 24px;
                }

                    &__performer-avatar {
                        width: 46px;
                        height: 46px;
                        margin-right: 20px;

                        border-radius: 50%;
                        background-size: cover;
                        background-position: 50% 50%;
                    }

                    &__performer-info {
                        padding-top: 6px;
                    }

                        &__performer-title {
                            display: block;
                            margin-bottom: 1px;

                            color: #848488;
                        }

                        &__performer-name {
                            color: #1e1e24;
                        }

                &__grade {

                }

                    &__grade-title {
                        display: block;
                        margin-bottom: 15px;

                        color: #848488;
                    }

                    &__grades {
                        display: flex;
                        justify-content: space-between;
                    }

                        &__grades-item {
                            width: 100%;
                            padding-top: 18px;
                            padding-bottom: 18px;
                            
                            text-align: center;
                            align-items: center;
                            font-size: 14px;
                            color: #848488;

                            background-color: #f0f0f5;
                            border-left: 1px solid #fff;

                            transition: .3s cubic-bezier(.65,.05,.36,1);

                            cursor: pointer;

                                &._color-1 {
                                    color: rgba(0,0,0,.63);
                                    
                                    background-color: #ff5f40;
                                }

                                &._color-2 {
                                    color: rgba(0,0,0,.63);
                                    
                                    background-color: #ffa340;
                                }

                                &._color-3 {
                                    color: rgba(0,0,0,.63);

                                    background-color: #deef27;
                                }

                                &._color-4 {
                                    color: #ffffff;

                                    background-color: #86d646;
                                }

                                &._color-5 {
                                    color: #ffffff;

                                    background-color: #56bf36;
                                }

                                &:first-of-type {
                                    border-bottom-left-radius: 12px;
                                    border-top-left-radius: 12px;
                                    border: none;
                                }

                                &:last-of-type {
                                    border-bottom-right-radius: 12px;
                                    border-top-right-radius: 12px;
                                }
                        }

                        &__grade-values {
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: -25px;

                            display: flex;
                            justify-content: space-between;
                        }

                            &__grade-value {
                                color: #aeaeba;
                            }

            &__comment {
                padding: 16px 30px;

                border-bottom: 1px solid #f1f1f6;
            }

                &__textarea {
                    display: block;
                    width: 100%;
                    height: 120px;
                    padding: 12px 16px;

                    color: #1e1e24;
                    font-weight: 500;
                    line-height: 18px;

                    border: 1px solid #f1f1f6;
                    border-radius: 3px;

                    resize: none;

                        &:focus {
                            padding: 11px 15px;

                            border: 2px solid #d0d0d9;
                        }
                }

            &__footer {
                display: flex;
                justify-content: space-between;
                padding: 16px 30px;
            }

                &__button {


                        &._gray {

                        }
                }
}
