.messages-actions {
    position: fixed;
    left: 1208px;
    top: 103px;


        &__item {
            margin-bottom: 8px;
            width: 40px;
            height: 40px;

            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            border: 1px solid #f7f7fc;

            cursor: pointer;

                &:before {
                    position: absolute;
                    top: -38px;
                    left: 50%;

                    display: block;
                    padding-top: 8px;
                    padding-bottom: 8px;

                    color: #ffffff;
                    font-weight: 500;
                    font-size: 12px;
                    text-align: center;

                    border-radius: 6px;
                    background-color: rgba(30,30,36,.9);
                    opacity: 0;

                    pointer-events: none;

                    transition: .3s;
                }

                &:hover {

                        &:before {
                            z-index: 10;

                            opacity: 1;
                        }
                }

                &._search {
                    background-image: url(__static__content/search-icon.png);
                    background-size: 16px;

                        &:before {
                            content: 'Поиск сообщений';

                            width: 10+110px;
                            margin-left: -60px;
                        }

                        &._active {
                            border-color: $main-color;
                            background-color: $main-color;
                            background-image: url(__static__content/close-icon.svg);
                        }
                }

                &._sound {
                    background-image: url(__static__content/sound-icon.svg);

                        &:before {
                            content: 'Отключить уведолмения';

                            width: 10+150px;
                            margin-left: -80px;
                        }
                }

                &._show-files {
                    background-image: url(__static__content/show-icon.svg);

                        &:before {
                            content: 'Показать вложения';

                            width: 10+120px;
                            margin-left: -65px;
                        }
                }

                &._clear {
                    background-image: url(__static__content/clear-icon.svg);

                        &:before {
                            content: 'Удалить диалог';

                            width: 10+90px;
                            margin-left: -50px;
                        }
                }

                &:last-child {
                    margin-bottom: 0;
                }
        }
}
