.my-portfolio {
    padding-top: 40px;
    padding-bottom: 7px;
    padding-left: 79px;
    padding-right: 79px;

        &__items {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

            &__item {
                display: block;
                margin-bottom: 30px;
                width: 240px;

                text-decoration: none;
            }

                &__item-preview {
                    height: 320px;

                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: 50% 50%;
                    border-radius: 5px;
                }

                &__item-title {
                    display: block;
                    margin-top: 16px;
                    padding-left: 6px;
                    padding-right: 10px;

                    font-size: 15px;
                    color: #1e1e24;
                    font-weight: 500;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    overflow: hidden;
                }
}
