.important-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;

                .important-item__content {
                    border-bottom: none;
                }
        }

        &__star {
            position: absolute;
            left: -27-18px;
            top: 5px;

            width: 18px;
            height: 18px;

            cursor: pointer;

            transition: .3s;

                use {
                    fill: $main-color;
                }
        }

        &__avatar {
            width: 34px;
            height: 34px;

            border-radius: 50%;
            background-position: 50% 50%;
            background-size: cover;
            background-repeat: no-repeat;
        }

        &__content {
            display: flex;
            width: 470px;

            padding-bottom: 20px;

            border-bottom: 1px solid #f1f1f6;
        }

            &__info {

            }

                &__title {
                    color: #000;
                }

                &__desc {
                    width: 270px;
                    margin-top: 2px;

                    font-size: 13px;
                    font-weight: 500;
                    line-height: 18px;
                    color: #686870;

                        p {
                            margin: 0;
                        }
                }

            &__date {
                margin-left: 50px;

                color: #b9b9c4;
                font-size: 12px;
            }
}
