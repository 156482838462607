.pick-performer {
    width: 470px;
    padding-top: 25px;

    background-color: #ffffff;
    border-radius: 12px;

        &__main {
            padding-left: 29px;
            padding-right: 30px;
            padding-bottom: 27px;

            border-bottom: 1px solid #f1f1f6;
        }

            &__title {
                display: block;
                margin-bottom: 20px;

                padding-left: 1px;
            }

            &__desc {
                margin-bottom: 25px;

                color: #797979;
                font-weight: 500;
                line-height: 17px;

                    p {
                        margin: 0;
                    }

                    b {
                        font-weight: 500;
                        color: #1e1e24;
                    }
            }

            &__person {
                display: flex;
                flex-wrap: wrap;
            }

                &__person-avatar {
                    width: 46px;
                    height: 46px;
                    margin-right: 20px;

                    border-radius: 50%;
                    background-size: cover;
                    background-position: 50% 50%;
                }

                &__person-info {
                    padding-top: 6px;
                }

                    &__person-title {
                        display: block;
                        margin-bottom: 1px;

                        color: #848488;
                    }

                    &__person-name {
                        text-decoration: none;
                        color: #1e1e24;

                        transition: .3s;

                            &:hover {
                                color: #e10a13;
                            }
                    }

                &__grade {
                    position: absolute;
                    right: 0;
                    top: 2px;

                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 42px;
                    height: 42px;

                    color: #fefeff;

                    border-radius: 50%;
                    background-color: #56bf36;

                        &._color-1 {
                            color: rgba(0,0,0,.63);
                            
                            background-color: #ff5f40;
                        }

                        &._color-2 {
                            color: rgba(0,0,0,.63);
                            
                            background-color: #ffa340;
                        }

                        &._color-3 {
                            color: rgba(0,0,0,.63);

                            background-color: #deef27;
                        }

                        &._color-4 {
                            color: #ffffff;

                            background-color: #86d646;
                        }

                        &._color-5 {
                            color: #ffffff;

                            background-color: #56bf36;
                        }
                }

        &__footer {
            display: flex;
            justify-content: space-between;
            padding: 16px 30px;
        }

            &__button {


                    &._gray {

                    }
            }
}
