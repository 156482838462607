.my-violations {

        &__items {
            padding-top: 2px;
        }

            &__item {
                display: flex;
                justify-content: space-between;
                padding-left: 39px;
                padding-top: 24px;

                    &:last-child {

                            .my-violations__content {
                                border-bottom: none;
                            }
                    }
            }

                &__date {
                    color: #bcbcc2;
                }

                &__content {
                    width: 508px;
                    padding-bottom: 20px;

                    border-bottom: 1px solid #f5f5fa;
                }

                    &__header {
                        display: flex;
                        justify-content: space-between;
                        width: 301px;
                    }

                        &__title {
                            margin-bottom: 11px;

                            color: #64646d;

                                &:empty {
                                    display: none;
                                }
                        }

                        &__subtitle {
                            color: #848488;

                                &:empty {
                                    display: none;
                                }
                        }

                    &__desc {
                        width: 238px;

                        line-height: 16px;
                        color: #848488;

                            p {
                                margin: 0;
                            }
                    }
}
