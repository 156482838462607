.second-navigation {
    @include nl;
    
    margin-top: 22px;
    display: flex;
    justify-content: center;

        .navigation-item__link {
            padding-bottom: 14px;
        }
}
