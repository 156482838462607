.share-answer {
    z-index: -10;
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;

    background-color: #fff;
    border-radius: 0 0 12px 12px;

    opacity: 0;

    transition: .3s;

        &._active {
            z-index: 10;
            bottom: -90px;

            opacity: 1;
        }

        &__form {

        }

            &__message-container, &__submit-container {
                padding-left: 20px;
                padding-right: 20px;
            }

            &__message-container {
                padding-top: 16px;
                padding-bottom: 16px;

                border-bottom: 1px solid #f1f1f6;
                border-top: 1px solid #f1f1f6;
            }

            &__submit-container {
                padding-top: 13px;
                padding-bottom: 11px;
            }

            &__message {
                width: 100%;
                height: 64px;
                padding-top: 10px;
                padding-left: 15px;
                padding-right: 15px;
                padding-bottom: 10px;

                color: #c0c0c4;
                font-weight: 500;

                border: 1px solid #f1f1f6;
                border-radius: 5px;
                background-color: #ffffff;

                resize: none;
            }

            &__submit {
                display: block;
                width: 100%;
                padding-top: 16px;
                padding-bottom: 15px;

                font-size: 14px;
                color: #ffffff;

                border-radius: 5px;
                background-color: $main-color;
                border: none;
                box-shadow: 0 -1px 0 #f1f1f6;

                transition: .3s;

                    &:hover {
                        box-shadow: 0 0 0 #f1f1f6;
                    }
            }
}
