.tag {
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 11px;
    padding-right: 11px;

    color: #9e9ea3;
    text-decoration: none;

    border-radius: 3px;
    background-color: #f2f2f7;
    
    transition: .3s;

        &._application {
            padding-top: 8px;
            padding-bottom: 9px;
            
            font-size: 12px;
            font-weight: 400;
        }

        &._cup {
            cursor: pointer;
        }

        &:hover {
            background-color: #ebebf0;
        }
}
