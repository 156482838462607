.portfolio {
    padding-bottom: 29px;

    border: 1px solid #f7f7fc;
    border-radius: 6px;

        &__header {
            display: flex;
            justify-content: space-between;
            padding-left: 28px;
            padding-right: 29px;
            padding-top: 29px;
            padding-bottom: 47px;

            border-bottom: 1px solid #f1f1f6;
        }

            &__info {
                width: 220px;
                padding-top: 12px;
            }

                &__title {
                    display: block;
                    margin-bottom: 15px;

                    color: #1e1e24;
                    line-height: 24px;
                    font-size: 19px;
                }

                &__desc {
                    color: #848488;
                    line-height: 18px;

                        p {
                            margin: 0;
                        }
                }

            &__date {
                margin-top: 12px;

                opacity: 0.56;
                color: #9e9ea3;
            }

            &__share-link {
                position: absolute;

                right: 18px;
                bottom: 18px;
            }

        &__items {
            padding-left: 29px;
            padding-right: 29px;
            padding-top: 34px;
        }

            &__images {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }

                &__image-link {
                    display: block;
                    width: 198px;
                    height: 224px;

                    text-decoration: none;

                    border: 1px solid #f7f7fc;
                    background-color: #f1f1f6;
                    border-radius: 6px;
                    overflow: hidden;
                }

                    &__image {
                        display: block;
                        height: 100%;
                        width: 100%;

                        border-radius: 6px;

                        object-fit: cover;
                    }

            &__video {
                margin-top: 8px;
                height: 230px;

                border: 1px solid #f7f7fc;
                background-color: #dcdcec;
                border-radius: 6px;
                overflow: hidden;

                cursor: pointer;

                    video, img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        border-radius: 6px;

                        object-fit: cover;
                    }

                    &:before {
                        content: '';
                        
                        position: absolute;
                        left: 50%;
                        top: 50%;

                        display: block;
                        width: 44px;
                        height: 44px;
                        margin-top: -22px;
                        margin-left: -22px;

                        border-radius: 50%;
                        border: 2px solid #ffffff;
                        background-image: url(__static__content/play-icon.png);
                        background-repeat: no-repeat;
                        background-position: 55% 50%;
                        background-size: 17.5px 20px;
                    }
            }
}
