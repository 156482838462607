.customer-actions {
    display: flex;
    justify-content: space-between;
    padding: 15px 29px;
    margin-top: 30px;
    
    border-top: 1px solid #f1f1f6;
    border-bottom: 1px solid #f1f1f6;

        &__button {

                &._gray {
                    padding-left: 40px;

                    background-image: url(__static__content/customer-edit-icon.png);
                    background-repeat: no-repeat;
                    background-position: 16px 50%;
                }
        }
}
