.share-user {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 336px;
    padding-bottom: 14px;
    padding-bottom: 14px;
    margin-bottom: 14px;

    cursor: pointer;

        &:last-child {
            margin-bottom: 30px;

                &:after {
                    display: none;
                }
        }

        &._active {

            .share-user__circle {

                    &:after {
                        opacity: 1;
                    }
            }
        }

        &:hover {

                .share-user__title {
                    color: $main-color;
                }
        }

        &:after {
            content: '';

            position: absolute;
            right: 0;
            bottom: 0;
            left: 60px;

            display: block;
            height: 1px;

            background-color: #f1f1f6;
        }

        &__main {

        }

            &__avatar, &__title {
                display: inline-block;
                vertical-align: top;
            }

            &__avatar {
                width: 40px;
                height: 40px;
                margin-right: 17px;

                border-radius: 50%;
                background-color: #ababab;
                background-size: cover;
                background-position: 50% 50%;
                overflow: hidden;
            }

            &__title {
                margin-top: 14px;

                transition: .3s;
            }

        &__circle {
            display: block;
            width: 18px;
            height: 18px;

            border-radius: 50%;
            border: 2px solid #d0d0d9;
            background-color: #ffffff;

            transition: .3s;

                &:after {
                    content: '';

                    position: absolute;
                    left: -2px;
                    right: -2px;
                    top: -2px;
                    bottom: -2px;

                    border-radius: 50%;
                    border: 3px solid $main-color;
                    background-color: #ffffff;
                    overflow: hidden;
                    opacity: 0;

                    transition: .3s;
                }
        }
}
