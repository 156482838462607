.my-reviews {
    padding-left: 25px;
    
        &._full {
            border-radius: 3px;
            border: 1px solid #f7f7fc;
        }

        &__items {
            padding-top: 45-28px;
        }

            &__item {
                display: flex;
                justify-content: space-between;
                padding-top: 28px;

                    &:last-child {

                            .my-reviews__main {
                                border-bottom: none;
                            }
                    }
            }

                &__grade {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 36px;
                    height: 36px;

                    color: #ffffff;
                    font-size: 14px;

                    border-radius: 50%;
                    background-color: #5dc43d;

                        &._color-1 {
                            color: rgba(0,0,0,.63);
                            
                            background-color: #ff5f40;
                        }

                        &._color-2 {
                            color: rgba(0,0,0,.63);
                            
                            background-color: #ffa340;
                        }

                        &._color-3 {
                            color: rgba(0,0,0,.63);

                            background-color: #deef27;
                        }

                        &._color-4 {
                            color: #ffffff;

                            background-color: #86d646;
                        }

                        &._color-5 {
                            color: #ffffff;

                            background-color: #56bf36;
                        }
                }

                &__main {
                    display: flex;
                    width: 560px;
                    padding-bottom: 21px;
                    border-bottom: 1px solid #f5f5fa;
                }

                    &__content {
                        margin-right: 71px;
                    }

                        &__title {
                            display: block;
                            width: 251px;
                            margin-bottom: 7px;

                            color: #1e1e24;
                            font-size: 16px;
                            line-height: 18px;
                            text-decoration: none;
                        }

                        &__desc {
                            width: 251px;
                            margin-bottom: 10px;

                            color: #64646d;
                            line-height: 18px;

                                p {
                                    margin: 0;
                                }
                        }

                        &__date {
                            display: block;

                            color: #9e9ea3;
                        }

                    &__customer {
                        display: flex;
                    }

                        &__customer-avatar {
                            width: 36px;
                            height: 36px;
                            margin-right: 20px;

                            text-decoration: none;

                            border-radius: 50%;
                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            background-size: cover;
                        }

                        &__customer-info {

                        }

                            &__customer-title {
                                display: block;
                                margin-bottom: 1px;

                                color: #848488;
                            }

                            &__customer-name {
                                color: #1e1e24;
                                text-decoration: none;
                            }
}
