.share {
    width: 410px;
    padding-top: 24px;

    background-color: #ffffff;
    border-radius: 12px;
    overflow: unset;

    transition: .3s;

        &._answering {
            transform: translateY(-80px);

                .share__content {
                    padding-bottom: 55px;
                }
        }

        &__content {
            padding-left: 26px;
            padding-right: 26px;
            padding-bottom: 28px;
        }

            &__title {
                display: block;
                margin-bottom: 34px;

                font-weight: 400;
                color: #1e1e24;
            }

            &__item {
                margin-bottom: 30px;

                    &:last-child {
                        margin-bottom: 0;
                    }
            }

                &__subtitle {
                    display: block;
                    margin-bottom: 12px;

                    color: #848488;
                }

                &__link {
                    display: flex;
                    justify-content: space-between;
                }

                    &__link-container {
                        width: 299px;
                        padding-top: 13px;
                        padding-bottom: 11px;
                        padding-left: 14px;
                        padding-right: 14px;

                        border-radius: 5px;
                        border: 1px solid #d0d0d9;
                        background-color: #ffffff;
                    }

                        &__link-value {
                            color: #1e1e24;
                        }

                        &__link-copy {
                            display: block;
                            width: 49px;
                            height: 42px;
                            
                            border-radius: 5px;
                            background-color: $main-color;
                            background-image: url(__static__content/copy-icons.png);
                            background-repeat: no-repeat;
                            background-position: -30px 1px;

                                &:hover {
                                    opacity: .92;
                                    background-position: 5px 1px;
                                }
                        }

                &__users {
                    height: 244px;
                    margin-top: 30px;
                    padding-left: 10px;
                    padding-right: 11px;

                    overflow-x: hidden;
                }

                    &__users-container {
                        width: 365px;
                        height: 100%;
                        padding-right: 13px;

                        overflow-y: scroll;
                    }

        &__form {
            padding-bottom: 0;
            height: auto;

            border-bottom: none;

                .form__search {
                    padding-top: 13px;
                    padding-bottom: 12px;

                    color: #1e1e24;

                    border-radius: 3px;
                }

                .form__submit-label {
                    width: 16px;
                    height: 16px;
                    margin-top: -8px;
                }
        }
}
