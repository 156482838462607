.sidebar {
    z-index: 20;
    position: fixed;
    top: 0;
    bottom: 0;

    width: 319px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-right: 1px solid #f7f7fc;

        &__top {

        }

            &__logo {
                display: block;
                margin-left: 89px;
                margin-top: 29px;
                margin-bottom: 29px;
                
                text-decoration: none;
            }

        &__footer {
            padding-bottom: 37px;
            padding-left: 48px;
        }
}
