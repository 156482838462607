.event-full {
    width: 650px;
    padding-top: 39px;
    padding-bottom: 16px;

    border-radius: 12px;
    border: 1px solid #f7f7fc;
    background-color: #ffffff;

        &._customer-view {

                .event-full__main {
                    border-bottom: none;
                }

                .event-full__item {

                        &:first-child {
                            order: 1;
                        }

                        &:last-child {
                            order: 0;
                        }
                }

                &.pop-up {
                    margin-bottom: 0;
                    padding-bottom: 72px;

                        .user-answer._customer-view {
                            padding-right: 0;
                        }

                        .event-full__share-link {
                            margin-left: 22px;
                        }

                        .event-full__item {
                            order: 0;

                                &._hide-on-answer {
                                    order: 1;
                                }
                        }

                        .event-full__items {
                            z-index: 20;
                            position: fixed;
                            bottom: 0;

                            width: 650px;
                            padding-bottom: 16px;

                            background-color: #fff;
                        }

                        .user-answer__name {

                                &:hover {
                                    color: #e10a13;
                                }
                        }
                }
        }
        
        &__edit {
            position: absolute;
            right: -70px;
            top: 50px;

            width: 40px;
            height: 40px;

            border-radius: 50%;
            background-image: url(__static__content/customer-edit-icon.png);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: #ffffff;

            overflow: hidden;

            transition: .3s;
        }

        &__main {
            padding-left: 39px;
            padding-right: 39px;
            padding-bottom: 29px;

            border-bottom: 1px solid #f1f1f6;
        }

            &__header {
                display: flex;
                justify-content: space-between;
                margin-bottom: 30px;
            }

                &__price {

                }

                    &__price-title {
                        display: block;

                        font-size: 24px;
                        color: #e1151e;
                        font-weight: 700;
                    }

                    &__price-subtitle {
                        display: block;

                        color: #a9a9af;
                        font-size: 12px;
                        text-transform: uppercase;
                    }

            &__info {

            }

                &__title {
                    display: block;
                    margin-bottom: 15px;

                    color: #1e1e24;
                    font-size: 24px;
                    font-weight: 700;
                }

                &__desc {
                    width: 571px;
                    margin-bottom: 26px;

                    font-weight: 500;
                    line-height: 22px;
                    font-size: 15px;
                    color: #56565c;

                        p {
                            margin: 0;
                        }
                }

            &__skills {
                margin-bottom: 29px;
            }

                &__skills-title {
                    display: block;
                    margin-bottom: 17px;

                    color: #8e8e96;
                }

                &__tags {
                    display: flex;
                }

                    &__tag {
                        margin-right: 6px;
                        margin-bottom: 6px;
                    }

            &__other {

            }

                &__other {

                }

                    &__other-items {
                        display: flex;
                        margin-bottom: 38px;
                    }

                        &__other-item {
                            margin-right: 104px;

                                &:last-child {
                                    margin-right: 0;
                                }
                        }

                            &__other-title {
                                display: block;
                                margin-bottom: 8px;

                                color: #8e8e96;
                            }

                            &__other-subtitle {
                                display: block;

                                color: #1e1e24;
                            }

                    &__other-info {
                        color: #9e9ea3;
                        font-size: 12px;
                        font-weight: 500;

                            b {
                                color: #1e1e24;
                                font-weight: 500;
                            }
                    }

        &__footer {

        }

            &__answers {

            }

                &__count {
                    display: flex;
                    margin-bottom: 37-29px;
                    padding-left: 39px;
                    padding-top: 29px;
                    padding-right: 39px;
                }

                    &__count-title {
                        margin-right: 16px;

                        color: #1e1e24;
                        font-size: 20px;
                    }

                    &__count-value {
                        color: #848488;
                        font-weight: 500;
                        font-size: 19px;
                    }

            &__items {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 16px;
                padding-left: 30px;
                padding-right: 29px;

                    &._answering {
                        padding-left: 0;
                        padding-right: 0;
                    }
            }

            &__item {
                display: flex;
                align-items: center;
                justify-content: space-between;

                transition: .3s;

                    &._show-on-answer {
                        margin-top: 16px;
                        padding-top: 16px;
                        padding-left: 30px;
                        padding-right: 29px;

                        border-top: 1px solid #f1f1f6;
                    }

                    &._hide-on-answer {

                    }

                    &._hide {
                        display: none;
                        opacity: 0;
                    }
            }

                &__form, &__item {

                }

                &__form {
                    display: block;
                    width: 100%;

                    transition: .3s;

                        &._new-answer {
                            z-index: 10;

                            opacity: 1;
                            background-color: #fff;

                            transition: .3s;
                        }

                        &._hide {
                            z-index: -10;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 100%;

                            opacity: 0;

                            pointer-events: none;
                        }
                }

                    &__textarea {
                        display: block;
                        width: 610px;
                        height: 100px;
                        margin: 0 auto;
                        padding-top: 10px;
                        padding-bottom: 12px;
                        padding-left: 14px;
                        padding-right: 16px;

                        font-weight: 500;
                        color: #1e1e24;

                        background-color: #ffffff;
                        border-radius: 5px;
                        border: 1px solid #e6e6eb;

                        resize: none;

                            &:focus {
                                border: 2px solid #d0d0d9;
                                padding-top: 9px;
                                padding-bottom: 11px;
                                padding-left: 13px;
                                padding-right: 15px;
                            }
                    }

                &__button {
                    margin-right: 22px;
                }

                &__share-link {

                }

                &__views {

                }

                &__answer-count {
                    position: relative;

                    padding-left: 26px;
                    margin-left: 29px;

                    color: #56565c;
                    font-size: 12px;

                        &:before {
                            content: '';

                            position: absolute;
                            left: 0;
                            top: -1px;

                            display: block;
                            width: 16px;
                            height: 15px;

                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            background-image: url(__static__content/answer-icon.png);
                        }
                }
}
