.user-answer {
    padding-left: 39px;
    padding-right: 39px;
    padding-top: 29px;
    padding-bottom: 24px;

    border-bottom: 1px solid #f1f1f6;

        &._customer-view {
            padding-right: 19px;
            padding-bottom: 2px;

            border-bottom: none;

                &:last-child {
                    padding-bottom: 0;
                }
            
                .user-answer__avatar {
                    width: 54px;
                    height: 54px;
                }

                .user-answer__city {
                    margin-bottom: 9px;
                }

                .user-answer__name {
                    font-size: 15px;
                }

                .user-answer__content {
                    width: 530px;
                    padding-right: 20px;

                    border-bottom: 1px solid #f1f1f6;
                }

                .user-answer__desc {
                    width: 481px;
                }

                .user-answer__rating {
                    top: 2px;
                }

                &:hover {

                        .user-answer__name {
                            margin-bottom: 19px;
                        }

                        .user-answer__rating {
                            z-index: -10;

                            opacity: 0;
                        }

                        .user-answer__choices {
                            z-index: 10;

                            opacity: 1;
                        }
                }
        }

        &__title {
            display: block;
            margin-bottom: 39px;

            color: #1e1e24;
            font-size: 20px;
        }

        &__body {
            display: flex;
            justify-content: space-between;
        }

            &__avatar {
                display: block;
                width: 80px;
                height: 80px;

                text-decoration: none;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;

                overflow: hidden;
            }

            &__content {
                width: 482px;
            }

                &__header {
                    display: flex;
                    justify-content: space-between;
                }

                    &__info {

                    }

                        &__city {
                            display: block;
                            margin-bottom: 5px;
                            
                            color: #a9a9af;
                        }

                        &__name {
                            display: block;
                            margin-bottom: 8px;

                            text-decoration: none;
                            color: #1e1e24;
                            font-size: 19px;

                            transition: .3s;
                        }

                        &__tags {
                            display: flex;
                            flex-wrap: wrap;
                        }

                            &__tag {
                                margin-right: 6px;
                            }

                    &__rating {
                        z-index: 10;
                        position: absolute;
                        top: 11px;
                        right: 0;

                        display: flex;
                        width: 54px;
                        height: 54px;
                        align-items: center;
                        justify-content: center;

                        font-size: 17px;
                        color: #ffffff;
                        font-weight: 500;
                        text-transform: uppercase;

                        background-color: #56bf36;
                        border-radius: 50%;

                        overflow: hidden;
                        transition: .3s;

                            &._color-1 {
                                color: rgba(0,0,0,.63);
                                
                                background-color: #ff5f40;
                            }

                            &._color-2 {
                                color: rgba(0,0,0,.63);
                                
                                background-color: #ffa340;
                            }

                            &._color-3 {
                                color: rgba(0,0,0,.63);

                                background-color: #deef27;
                            }

                            &._color-4 {
                                color: #ffffff;

                                background-color: #86d646;
                            }

                            &._color-5 {
                                color: #ffffff;

                                background-color: #56bf36;
                            }
                    }

                    &__choices {
                        z-index: -10;
                        position: absolute;
                        right: 20px;
                        top: 2px;

                        display: flex;
                        flex-wrap: wrap;

                        opacity: 0;

                        transition: .3s;
                    }

                        &__choice {
                            position: relative;

                            width: 40px;
                            height: 40px;
                            margin-right: 8px;

                            border-radius: 50%;
                            background-position: 50% 50%;
                            background-repeat: no-repeat;
                            
                            transition: .3s;

                                &:before {
                                    z-index: -10;
                                    position: absolute;
                                    top: -36px;
                                    left: 50%;

                                    display: block;
                                    padding-top: 8px;
                                    padding-bottom: 7px;

                                    color: #ffffff;
                                    font-weight: 500;
                                    font-size: 12px;
                                    text-align: center;

                                    border-radius: 6px;
                                    background-color: rgba(30,30,36,.9);
                                    opacity: 0;

                                    transition: .3s;
                                }

                                &._accept {
                                    background-color: #e00a13;
                                    background-image: url(__static__content/accept-icon.svg);

                                        &:before {
                                            content: 'Выбрать исполнителем';

                                            width: 162px;
                                            margin-left: -81px;
                                        }
                                }

                                &._decline {
                                    background-color: #f0f0f5;
                                    background-image: url(__static__content/decline-icon.svg);

                                        &:before {
                                            content: 'Отказать';

                                            width: 94px;
                                            margin-left: -47px;
                                        }
                                }

                                &:last-child {
                                    margin-right: 0;
                                }

                                &:hover {
                                    opacity: .92;

                                        &:before {
                                            z-index: 10;

                                            opacity: 1;
                                        }
                                }
                        }

                &__main {
                    margin-top: 20px;
                }

                    &__desc {
                        margin-bottom: 18px;

                        color: #56565c;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20px;

                            p {
                                margin: 0;
                            }
                    }

                    &__textarea {
                        display: block;
                        margin-bottom: 22px;
                        width: 100%;
                        padding-top: 12px;
                        padding-left: 12px;
                        padding-bottom: 12px;
                        padding-right: 18px;

                        font-size: 13px;
                        color: #1e1e24;
                        font-weight: 500;
                        line-height: 18px;

                        border-radius: 3px;
                        border: 2px solid #d0d0d9;

                        resize: none;
                    }

                    &__buttons {
                        display: flex;
                        flex-wrap: wrap;
                    }

                        &__button {
                            margin-right: 12px;

                                &._gray {

                                }
                        }
}
