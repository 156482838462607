.notifications {
    position: absolute;
    left: 100%;
    top: -41px;

    width: 360px;
    padding-top: 25px;
    padding-bottom: 24px;
    padding-left: 30px;

    box-shadow: 0 10px 24px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    background-color: #ffffff;

    opacity: 0;

    transition: .3s;

    pointer-events: none;

        &._active {
            opacity: 1;

            pointer-events: all;
        }

        &__title {
            display: block;
            margin-bottom: 36px;

            color: #1e1e24;
        }
}
