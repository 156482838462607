.my-rating {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 20px;
    padding-left: 16px;
    padding-right: 34px;

    border-bottom: 1px solid #f5f5fa;

        &__list {
            @include nl;

            display: flex;
        }

            &__list-item {
                position: relative;

                padding-top: 36+11px;
                padding-bottom: 20px;
                padding-right: 18px;
                padding-left: 18px;
                margin-right: 9px;

                color: #848488;

                border-bottom: 2px solid transparent;

                cursor: pointer;
                transition: .3s;

                    &:before {
                        content: '';

                        position: absolute;
                        top: 0;
                        left: 50%;

                        margin-left: -18px;
                        width: 36px;
                        height: 36px;

                        border-radius: 50%;
                        background-color: #f5f5f5;

                        transition: .3s;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    &._all {
                        margin-right: 48px;

                            &:before {
                                border: 1px solid #dedede;
                            }
                    }

                    &._color-1 {

                            &:before {
                                background-color: #ff5f40;
                            }
                    }

                    &._color-2 {

                            &:before {
                                background-color: #ffa340;
                            }
                    }

                    &._color-3 {

                            &:before {
                                background-color: #deef27;
                            }
                    }

                    &._color-4 {

                            &:before {
                                background-color: #86d646;
                            }
                    }

                    &._color-5 {

                            &:before {
                                background-color: #56bf36;
                            }
                    }

                    &._active {
                        color: #1e1e24;

                        border-bottom-color: #e1151e;
                    }
            }

        &__main {
            padding-bottom: 23px;
        }

            &__main-item {
                color: rgba(100,100,109,.7);

                    b {
                        position: relative;

                        margin-left: 14px;

                        font-weight: 400;
                        color: #343434;

                            &:before {
                                content: '';

                                position: absolute;
                                top: -36-11px;
                                left: 50%;

                                display: block;
                                height: 36px;
                                width: 36px;
                                margin-left: -18px;

                                background-color: #90d658;
                                border-radius: 50%;
                            }

                            &._color-1 {

                                &:before {
                                    background-color: #ff5f40;
                                }
                            }

                            &._color-2 {

                                &:before {
                                    background-color: #ffa340;
                                }
                            }

                            &._color-3 {

                                &:before {
                                    background-color: #deef27;
                                }
                            }

                            &._color-4 {

                                &:before {
                                    background-color: #86d646;
                                }
                            }

                            &._color-5 {

                                &:before {
                                    background-color: #56bf36;
                                }
                            }
                    }
            }
}
