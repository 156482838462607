.violators-table {
    display: block;
    width: 100%;

        &__header {
            height: 50px;

            font-weight: 500;
            color: #bcbcc2;

                .violators-table__reason {
                    color: #bcbcc2;
                }
        }

        &__item {
            height: 61px;

            border-bottom: 1px solid #f5f5fa;

                &:last-child {
                    border-bottom: none;
                }
        }

            &__item-avatar {
                width: 30px;
                height: 30px;
                margin-right: 15px;

                text-decoration: none;

                border-radius: 50%;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
            }

            &__user {
                width: 156+84px;

                    a {
                        display: flex;
                        align-items: center;

                        text-decoration: none;
                        color: #1e1e24;
                        font-weight: 500;
                    }
            }

            &__status {
                width: 94+55px;

                color: #bcbcc2;
                font-weight: 500;

                    b {
                        font-weight: 500;
                        color: #64646d;
                    }
            }

            &__reason {
                width: 240px;

                color: #848488;
                font-weight: 500;
            }
}
