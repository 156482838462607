// Mixins.

@mixin nl {
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style: none;
    list-style-position: outside;
}

// You can add your own mixins here: