.messages-panel {
    display: flex;
    justify-content: space-between;

        &__attach {

        }

            &__attach-icon {
                display: block;
                width: 12px;
                height: 25px;
                margin-top: 7px;

                text-decoration: none;

                background-image: url(__static__content/attach-icon.svg);
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: 12px 25px;
            }

            &__attach-items {
                z-index: -10;
                position: absolute;
                left: -28px;
                top: -155px;

                display: flex;
                width: 483px;
                height: 141px;

                border: 1px solid #f7f7fc;
                background-color: #fff;
                opacity: 0;

                pointer-events: none;

                transition: .3s;

                    &._active {
                        z-index: 10;

                        opacity: 1;

                        pointer-events: all;
                    }

                    &:after {
                        content: '';

                        position: absolute;
                        left: -28px;
                        bottom: -30px;

                        width: 483px;
                        height: 30px;
                    }
            }
            
                &__attach-item {
                    width: 100%;

                    background-position: 50% 60%;
                    background-repeat: no-repeat;
                    border-right: 1px solid #f7f7fc;

                    cursor: pointer;

                        &:last-child {
                            border-right: none;
                        }

                        &:hover {

                                .messages-panel__attach-title {
                                    color: #1e1e24;
                                }
                        }

                        &._photo {
                            background-image: url(__static__content/attach-icon-photo.png);
                            background-size: 44px 36px;
                        }

                        &._video {
                            background-image: url(__static__content/attach-icon-video.png);
                            background-size: 32px 36px;
                        }

                        &._document {
                            background-image: url(__static__content/attach-icon-document.png);
                            background-size: 32px 36px;
                        }
                }

                    &__attach-title {
                        display: block;
                        padding-left: 18px;
                        padding-top: 21px;

                        color: #64646d;

                        transition: .3s;
                    }

        &__input {
            display: block;
            width: 514px;
            height: 42px;
            padding-left: 13px;
            padding-right: 13px;

            color: #1e1e24;
            line-height: 13px;
            
            border: none;
            border-radius: 3px;
            background-color: #f7f7fc;
            border: 2px solid rgba(208,208,217,0);

            transition: .3s;

                &:focus {
                    background-color: #f5f5fa;
                    border: 2px solid rgba(208,208,217,1);
                }
        }

        &__send {
            width: 42px;
            height: 42px;

            text-decoration: none;

            border-radius: 50%;
            background-image: url(__static__content/send-icon.svg);
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: #f7f7fa;
            background-size: 14px 17px;

            transition: background-color .3s;

                &._active {
                    background-color: $main-color;
                    background-image: url(__static__content/send-icon-active.png);
                }
        }
}
