.users {
    border-radius: 3px;
    border: 1px solid #f7f7fc;

        &__container {
            padding-left: 29px;
            padding-top: 7px;
            
            border-top: 1px solid #f7f7fc;
        }
}
