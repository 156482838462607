.event {
    padding-top: 26px;
    padding-bottom: 22px;

    border-bottom: 2px solid #f1f1f6;
    
        &__header {

        }

            &__date {
                display: block;
                margin-bottom: 12px;

                font-weight: 500;
                color: #a9a9af;
            }

            &__title-line {
                display: flex;
                justify-content: space-between;
            }

                &__title {
                    color: #32323a;
                    font-size: 24px;
                    font-weight: 700;
                }

                &__price {
                    position: absolute;
                    right: 0;

                    text-align: right;
                }

                    &__price-value {
                        color: #1e1e24;
                        font-size: 24px;
                        font-weight: 700;
                    }

                    &__price-title {
                        display: block;
                        min-width: 150px;
                        margin-top: 3px;

                        color: #a9a9af;
                        font-weight: 500;
                        font-size: 15px;
                    }

            &__tags {
                display: inline-flex;
                flex-wrap: wrap;
                margin-top: 14px;
            }

                &__tag {
                    margin-right: 6px;
                    margin-bottom: 17px;

                        &:last-child {
                            margin-right: 0;
                        }
                }

        &__body {

        }

            &__main {
                display: flex;
                justify-content: space-between;
            }

                &__desc {
                    width: 444px;

                    text-decoration: none;
                    color: #56565c;
                    font-size: 13px;
                    line-height: 20px;
                    font-weight: 500;

                        p {
                            margin: 0;
                        }
                }

                &__customer {
                    min-width: 150px;

                    text-align: right;
                }

                    &__customer-title {
                        display: block;

                        font-size: 11px;
                        text-transform: uppercase;
                        color: #848488;
                    }

                    &__customer-name {
                        display: block;
                        margin-top: 5px;

                        color: #1e1e24;
                        font-size: 13px;
                        line-height: 15px;
                    }

            &__other {
                display: flex;
                justify-content: space-between;
                margin-top: 30px;
                padding-bottom: 31px;

                border-bottom: 2px solid #f1f1f6;
            }

                &__info {

                        &:last-child {

                            .event__info-title {
                                margin-right: 17px;
                            }
                        }
                }

                    &__info-title, &__info-value {
                        display: inline-block;
                        vertical-align: top;
                    }

                    &__info-title {
                        margin-right: 18px;

                        color: #8e8e96;
                    }

                    &__info-value {
                        color: #1e1e24;
                    }

        &__footer {
            display: flex;
            justify-content: space-between;
            padding-top: 21px;
        }

            &__footer-item {
                display: flex;

                font-size: 12px;

                    &:first-child {
                        padding-top: 2px;
                    }
            }

                &__views {
                    margin-right: 29px;
                }

                &__your-answer {
                    position: relative;

                    display: block;
                    padding-left: 26px;

                    color: $main-color;
                    text-decoration: none;

                        &:before {
                            content: '';

                            position: absolute;
                            left: 0;
                            top: -3px;

                            display: block;
                            width: 19px;
                            height: 18px;

                            background-repeat: no-repeat;
                            background-position: 50% 50%;
                            background-image: url(__static__content/answer-icon.svg);
                        }
                }

                &__share-link {
                    
                }
}
