.message {
    margin-bottom: 14px;

        &:last-child {
            margin-bottom: 0;
        }

        &._you {

                .message__star {
                    right: -27-18px;
                    left: auto;
                }

                .message__main {
                    flex-direction: row-reverse;
                }

                .message__avatar {
                    margin-right: 0;
                    margin-left: 14px;
                }

                .message__desc {
                    background-color: #dcdcec;
                }
        }

        &:hover {

                .message__star {
                    opacity: 1;
                }
        }

        &__star {
            position: absolute;
            left: -27-18px;
            top: 5px;

            width: 18px;
            height: 18px;

            opacity: 0;

            cursor: pointer;

            transition: .3s;

                use {
                    transition: .3s;
                }

                &:hover, &._active {
                    opacity: 1;

                        use {
                            fill: $main-color;
                        }
                }
        }

        &__main {
            display: flex;
        }

            &__avatar {
                width: 26px;
                height: 26px;
                margin-right: 14px;

                text-decoration: none;
                
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                border-radius: 50%;

                    &._hide {
                        opacity: 0;

                        pointer-events: none;
                    }
            }

            &__content {

                    &._attach {

                            .message__desc {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                padding-left: 0;
                                padding-right: 0;

                                background-color: transparent;
                            }
                    }
            }

            &__desc {
                max-width: 320px;
                min-width: 50px;
                padding-bottom: 23px;
                padding-top: 7px;
                padding-left: 10px;
                padding-right: 10px;

                font-weight: 500;
                color: #1e1e24;
                line-height: 18px;

                border-radius: 6px;
                background-color: #f5f5f5;

                    p {
                        margin: 0;
                    }
            }

                &__photo {
                    margin-bottom: 10px;

                        img {
                            width: 100%;
                            height: 100%;

                            object-fit: cover;

                            border-radius: 6px;
                        }

                        &._three {
                            width: 320px;
                            height: 320px;

                                &:nth-child(n+2) {
                                    width: 155px;
                                    height: 155px;
                                }
                        }

                        &._five {
                            width: 155px;
                            height: 155px;

                                &:nth-child(n+3) {
                                    width: 100px;
                                    height: 100px;
                                }
                        }
                }

            &__time {
                position: absolute;
                bottom: 7px;
                right: 9px;

                color: #9b9ba4;
                font-size: 11px;
                font-weight: 500;
            }
}
