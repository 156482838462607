.violators {
    margin-top: 249px;

    border-radius: 3px;
    border: 1px solid #f7f7fc;
    border-top: none;

        &__header {
            z-index: 14;
            position: fixed;
            top: 26px;

            width: 670px;

            background-color: #fff;
            border: 1px solid #f7f7fc;
            border-bottom: none;

                &:before {
                    content: '';

                    position: fixed;
                    top: 0;

                    height: 249px;
                    width: 672px;
                    margin-left: -2px;
                    
                    background-color: #fff;
                }
        }

            &__header-info {
                padding-top: 27px;
                padding-right: 111px;
                padding-left: 40px;
                padding-bottom: 35px;

                border: 1px solid #f7f7fc;
            }

                &__title {
                    display: block;
                    margin-bottom: 11px;
                    font-size: 24px;

                    color: #1e1e24;
                }

                &__desc {
                    color: #848488;
                    line-height: 19px;
                    font-weight: 500;
                    font-size: 14px;

                        p {
                            margin: 0;
                        }
                }

            &__search {
                padding: 16px;
                padding-bottom: 0;

                border-bottom: 1px solid #f5f5fa;
            }

        &__items {
            padding-left: 39px;
        }
}
