.navigation {
    margin-top: 26px;
    margin-bottom: 20px;
    min-height: 119px;

        &._small {
            margin-top: 0;
            min-height: 65px;
        }

        &._messages {
            margin-bottom: 0;

                .navigation__container {

                        &._fixed {
                            top: 26px;
                        }
                }
        }

        &._friends {
            margin-top: 0;
        }

        &__container {
            padding-top: 24px;
            border: 1px solid #f7f7fc;
            background-color: #fff;
            border-radius: 3px;

                &._fixed {
                    z-index: 15;
                    position: fixed;
                    top: 0;
                    width: 670px;
                }
        }
}
