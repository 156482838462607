.my-profile {

        &._portfolio {

                .my-profile__header {
                    align-items: center;
                }

                .my-profile__avatar {
                    width: 64px;
                    height: 64px;
                }

                .my-profile__rating {
                    top: 50%;

                    width: 48px;
                    height: 48px;
                    margin-top: -24px;

                    font-size: 15px;
                }
        }

        &__header {
            display: flex;
            padding: 25px 30px;
            margin-bottom: 10px;

            border-radius: 3px;
            border: 1px solid #f7f7fc;
            background-color: #ffffff;
        }

            &__avatar {
                width: 100px;
                height: 100px;
                margin-right: 30px;

                text-decoration: none;
                
                background-repeat: no-repeat;
                background-position: 50% 50%;
                background-size: cover;
                border-radius: 50%;
            }

            &__header-info {
                padding-top: 5px;
            }

                &__city {
                    display: block;
                    margin-bottom: 2px;

                    color: #9e9ea3;
                }

                &__name {
                    display: block;
                    margin-bottom: 10px;

                    color: #1e1e24;
                    font-size: 19px;
                    text-decoration: none;
                }

                &__edit {
                    padding-left: 39px;
                    padding-right: 16px;
                    padding-top: 11px;
                    padding-bottom: 11px;

                    text-decoration: none;
                    font-size: 12px;

                    background-image: url(__static__content/customer-edit-icon.png);
                    background-repeat: no-repeat;
                    background-position: 16px 50%;
                    border-radius: 18px;
                    background-color: #f0f0f5;

                    transition: .3s;
                }

            &__rating {
                position: absolute;
                top: 50%;
                right: 30px;

                display: flex;
                justify-content: center;
                align-items: center;
                width: 60px;
                height: 60px;
                margin-top: -30px;

                font-size: 18px;
                font-weight: 500;
                text-transform: uppercase;
                color: #ffffff;

                background-color: #90d658;
                border-radius: 50%;

                    &._color-1 {
                        color: rgba(0,0,0,.63);
                        
                        background-color: #ff5f40;
                    }

                    &._color-2 {
                        color: rgba(0,0,0,.63);
                        
                        background-color: #ffa340;
                    }

                    &._color-3 {
                        color: rgba(0,0,0,.63);

                        background-color: #deef27;
                    }

                    &._color-4 {
                        color: #ffffff;

                        background-color: #86d646;
                    }

                    &._color-5 {
                        color: #ffffff;

                        background-color: #56bf36;
                    }
            }

        &__columns {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
        }

            &__column {
                width: 330px;
                padding: 19px;
                padding-bottom: 25px;

                border-radius: 3px;
                border: 1px solid #f7f7fc;
                background-color: #ffffff;
            }

                &__column-header {
                    display: flex;
                    justify-content: space-between;
                }

                    &__column-title {
                        display: block;

                        color: #1e1e24;
                    }

                    &__column-count {
                        display: block;

                        text-decoration: none;
                        color: #9e9ea3;
                    }

        &__content {
            margin-top: 10px;
            padding-bottom: 12px;

            border: 1px solid #f7f7fc;
            background-color: #ffffff;
            border-radius: 3px;
        }
}
