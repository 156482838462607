.sidebar-nav {
    @include nl;
    
    padding-top: 130px;

        &__item {
            position: relative;

                &._active {
                    border-left: 3px solid $main-color;

                    .sidebar-nav__item-link {
                        color: #1e1e24;

                        padding-left: 46px;
                    
                            &:hover {
                                padding-left: 46px;

                                color: #1e1e24;
                            }
                    }
                }
        }

            &__item-link {
                display: block;
                padding-left: 49px;
                padding-top: 10px;
                padding-bottom: 10px;

                text-decoration: none;
                color: #848488;
                font-size: 15px;

                transition: .3s;

                    &:hover {
                        padding-left: 55px;

                        color: #64646d;
                    }
            }

            &__item-messages {
                position: absolute;
                right: 40px;
                top: 50%;

                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                height: 30px;
                margin-top: -15px;

                color: #ffffff;

                border-radius: 50%;
                background-color: #ec1e27;
            }
}
